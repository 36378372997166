@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply font-primary text-primary;
    background-image: linear-gradient(to right, #fe024c, #03a8e3);
    /* color: white; */
  }
}

* {
  margin: 0;
  padding: 0;
}

.main_background {
  background-image: linear-gradient(to right, #fe024c, #03a8e3);
}

.main_background_h1 {
  text-align: center;
  color: white;
  font-weight: 500;
  font-size: 18px;
  margin-top: 5px;
}

.main_background_h1_span {
  color: white;
  font-weight: 500;
  font-size: 18px;
  opacity: 0.8;
}

.shine_img {
  /* position: relative;
  overflow: hidden; */
}
.shine_img::before {
  /* background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.3) 100%
  );
  content: "";
  display: block;
  height: 100%;
  left: -75%;
  position: absolute;
  top: 0;
  transform: skewX(-25deg);
  width: 50%;
  z-index: 2; */
}
.shine_img:hover::before,
.shine_img:focus::before {
  /* -webkit-animation: shine 0.85s;
  animation: shine 0.85s; */
}
/* @-webkit-keyframes shine {
  100% {
    left: 125%;
  }
}
@keyframes shine {
  100% {
    left: 125%;
  }
} */

.container {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}

.figry_cart_modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.circle-container {
  position: absolute;
  transform: translateY(-10vh);
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}
.circle-container .circle {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  mix-blend-mode: screen;
  background-image: radial-gradient(
    #99ffff,
    #99ffff 10%,
    rgba(153, 255, 255, 0) 56%
  );
  -webkit-animation: fadein-frames 200ms infinite, scale-frames 2s infinite;
  animation: fadein-frames 200ms infinite, scale-frames 2s infinite;
}
@-webkit-keyframes fade-frames {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-frames {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes scale-frames {
  0% {
    transform: scale3d(0.4, 0.4, 1);
  }
  50% {
    transform: scale3d(2.2, 2.2, 1);
  }
  100% {
    transform: scale3d(0.4, 0.4, 1);
  }
}
@keyframes scale-frames {
  0% {
    transform: scale3d(0.4, 0.4, 1);
  }
  50% {
    transform: scale3d(2.2, 2.2, 1);
  }
  100% {
    transform: scale3d(0.4, 0.4, 1);
  }
}
.circle-container:nth-child(1) {
  width: 10px;
  height: 10px;
  -webkit-animation-name: move-frames-1;
  animation-name: move-frames-1;
  -webkit-animation-duration: 31253ms;
  animation-duration: 31253ms;
  -webkit-animation-delay: 628ms;
  animation-delay: 628ms;
}
@-webkit-keyframes move-frames-1 {
  from {
    transform: translate3d(76vw, 104vh, 0);
  }
  to {
    transform: translate3d(41vw, -119vh, 0);
  }
}
@keyframes move-frames-1 {
  from {
    transform: translate3d(76vw, 104vh, 0);
  }
  to {
    transform: translate3d(41vw, -119vh, 0);
  }
}
.circle-container:nth-child(1) .circle {
  -webkit-animation-delay: 3301ms;
  animation-delay: 3301ms;
}
.circle-container:nth-child(2) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-2;
  animation-name: move-frames-2;
  -webkit-animation-duration: 36732ms;
  animation-duration: 36732ms;
  -webkit-animation-delay: 21812ms;
  animation-delay: 21812ms;
}
@-webkit-keyframes move-frames-2 {
  from {
    transform: translate3d(93vw, 105vh, 0);
  }
  to {
    transform: translate3d(18vw, -109vh, 0);
  }
}
@keyframes move-frames-2 {
  from {
    transform: translate3d(93vw, 105vh, 0);
  }
  to {
    transform: translate3d(18vw, -109vh, 0);
  }
}
.circle-container:nth-child(2) .circle {
  -webkit-animation-delay: 1520ms;
  animation-delay: 1520ms;
}
.circle-container:nth-child(3) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-3;
  animation-name: move-frames-3;
  -webkit-animation-duration: 34283ms;
  animation-duration: 34283ms;
  -webkit-animation-delay: 21336ms;
  animation-delay: 21336ms;
}
@-webkit-keyframes move-frames-3 {
  from {
    transform: translate3d(44vw, 108vh, 0);
  }
  to {
    transform: translate3d(6vw, -113vh, 0);
  }
}
@keyframes move-frames-3 {
  from {
    transform: translate3d(44vw, 108vh, 0);
  }
  to {
    transform: translate3d(6vw, -113vh, 0);
  }
}
.circle-container:nth-child(3) .circle {
  -webkit-animation-delay: 731ms;
  animation-delay: 731ms;
}
.circle-container:nth-child(4) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-4;
  animation-name: move-frames-4;
  -webkit-animation-duration: 33590ms;
  animation-duration: 33590ms;
  -webkit-animation-delay: 3811ms;
  animation-delay: 3811ms;
}
@-webkit-keyframes move-frames-4 {
  from {
    transform: translate3d(57vw, 102vh, 0);
  }
  to {
    transform: translate3d(82vw, -115vh, 0);
  }
}
@keyframes move-frames-4 {
  from {
    transform: translate3d(57vw, 102vh, 0);
  }
  to {
    transform: translate3d(82vw, -115vh, 0);
  }
}
.circle-container:nth-child(4) .circle {
  -webkit-animation-delay: 2701ms;
  animation-delay: 2701ms;
}
.circle-container:nth-child(5) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-5;
  animation-name: move-frames-5;
  -webkit-animation-duration: 31632ms;
  animation-duration: 31632ms;
  -webkit-animation-delay: 17488ms;
  animation-delay: 17488ms;
}
@-webkit-keyframes move-frames-5 {
  from {
    transform: translate3d(67vw, 101vh, 0);
  }
  to {
    transform: translate3d(92vw, -121vh, 0);
  }
}
@keyframes move-frames-5 {
  from {
    transform: translate3d(67vw, 101vh, 0);
  }
  to {
    transform: translate3d(92vw, -121vh, 0);
  }
}
.circle-container:nth-child(5) .circle {
  -webkit-animation-delay: 2023ms;
  animation-delay: 2023ms;
}
.circle-container:nth-child(6) {
  width: 5px;
  height: 5px;
  -webkit-animation-name: move-frames-6;
  animation-name: move-frames-6;
  -webkit-animation-duration: 28705ms;
  animation-duration: 28705ms;
  -webkit-animation-delay: 29287ms;
  animation-delay: 29287ms;
}
@-webkit-keyframes move-frames-6 {
  from {
    transform: translate3d(31vw, 102vh, 0);
  }
  to {
    transform: translate3d(79vw, -126vh, 0);
  }
}
@keyframes move-frames-6 {
  from {
    transform: translate3d(31vw, 102vh, 0);
  }
  to {
    transform: translate3d(79vw, -126vh, 0);
  }
}
.circle-container:nth-child(6) .circle {
  -webkit-animation-delay: 3305ms;
  animation-delay: 3305ms;
}
.circle-container:nth-child(7) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-7;
  animation-name: move-frames-7;
  -webkit-animation-duration: 35423ms;
  animation-duration: 35423ms;
  -webkit-animation-delay: 853ms;
  animation-delay: 853ms;
}
@-webkit-keyframes move-frames-7 {
  from {
    transform: translate3d(9vw, 105vh, 0);
  }
  to {
    transform: translate3d(21vw, -123vh, 0);
  }
}
@keyframes move-frames-7 {
  from {
    transform: translate3d(9vw, 105vh, 0);
  }
  to {
    transform: translate3d(21vw, -123vh, 0);
  }
}
.circle-container:nth-child(7) .circle {
  -webkit-animation-delay: 631ms;
  animation-delay: 631ms;
}
.circle-container:nth-child(8) {
  width: 4px;
  height: 4px;
  -webkit-animation-name: move-frames-8;
  animation-name: move-frames-8;
  -webkit-animation-duration: 35846ms;
  animation-duration: 35846ms;
  -webkit-animation-delay: 25248ms;
  animation-delay: 25248ms;
}
@-webkit-keyframes move-frames-8 {
  from {
    transform: translate3d(66vw, 102vh, 0);
  }
  to {
    transform: translate3d(76vw, -132vh, 0);
  }
}
@keyframes move-frames-8 {
  from {
    transform: translate3d(66vw, 102vh, 0);
  }
  to {
    transform: translate3d(76vw, -132vh, 0);
  }
}
.circle-container:nth-child(8) .circle {
  -webkit-animation-delay: 1251ms;
  animation-delay: 1251ms;
}
.circle-container:nth-child(9) {
  width: 8px;
  height: 8px;
  -webkit-animation-name: move-frames-9;
  animation-name: move-frames-9;
  -webkit-animation-duration: 36301ms;
  animation-duration: 36301ms;
  -webkit-animation-delay: 27665ms;
  animation-delay: 27665ms;
}
@-webkit-keyframes move-frames-9 {
  from {
    transform: translate3d(94vw, 102vh, 0);
  }
  to {
    transform: translate3d(28vw, -110vh, 0);
  }
}
@keyframes move-frames-9 {
  from {
    transform: translate3d(94vw, 102vh, 0);
  }
  to {
    transform: translate3d(28vw, -110vh, 0);
  }
}
.circle-container:nth-child(9) .circle {
  -webkit-animation-delay: 2867ms;
  animation-delay: 2867ms;
}
.circle-container:nth-child(10) {
  width: 5px;
  height: 5px;
  -webkit-animation-name: move-frames-10;
  animation-name: move-frames-10;
  -webkit-animation-duration: 30225ms;
  animation-duration: 30225ms;
  -webkit-animation-delay: 16815ms;
  animation-delay: 16815ms;
}
@-webkit-keyframes move-frames-10 {
  from {
    transform: translate3d(77vw, 109vh, 0);
  }
  to {
    transform: translate3d(55vw, -121vh, 0);
  }
}
@keyframes move-frames-10 {
  from {
    transform: translate3d(77vw, 109vh, 0);
  }
  to {
    transform: translate3d(55vw, -121vh, 0);
  }
}
.circle-container:nth-child(10) .circle {
  -webkit-animation-delay: 3211ms;
  animation-delay: 3211ms;
}
.circle-container:nth-child(11) {
  width: 5px;
  height: 5px;
  -webkit-animation-name: move-frames-11;
  animation-name: move-frames-11;
  -webkit-animation-duration: 31156ms;
  animation-duration: 31156ms;
  -webkit-animation-delay: 4738ms;
  animation-delay: 4738ms;
}
@-webkit-keyframes move-frames-11 {
  from {
    transform: translate3d(43vw, 109vh, 0);
  }
  to {
    transform: translate3d(7vw, -125vh, 0);
  }
}
@keyframes move-frames-11 {
  from {
    transform: translate3d(43vw, 109vh, 0);
  }
  to {
    transform: translate3d(7vw, -125vh, 0);
  }
}
.circle-container:nth-child(11) .circle {
  -webkit-animation-delay: 1393ms;
  animation-delay: 1393ms;
}
.circle-container:nth-child(12) {
  width: 8px;
  height: 8px;
  -webkit-animation-name: move-frames-12;
  animation-name: move-frames-12;
  -webkit-animation-duration: 32920ms;
  animation-duration: 32920ms;
  -webkit-animation-delay: 9836ms;
  animation-delay: 9836ms;
}
@-webkit-keyframes move-frames-12 {
  from {
    transform: translate3d(75vw, 104vh, 0);
  }
  to {
    transform: translate3d(96vw, -125vh, 0);
  }
}
@keyframes move-frames-12 {
  from {
    transform: translate3d(75vw, 104vh, 0);
  }
  to {
    transform: translate3d(96vw, -125vh, 0);
  }
}
.circle-container:nth-child(12) .circle {
  -webkit-animation-delay: 1776ms;
  animation-delay: 1776ms;
}
.circle-container:nth-child(13) {
  width: 5px;
  height: 5px;
  -webkit-animation-name: move-frames-13;
  animation-name: move-frames-13;
  -webkit-animation-duration: 30177ms;
  animation-duration: 30177ms;
  -webkit-animation-delay: 33059ms;
  animation-delay: 33059ms;
}
@-webkit-keyframes move-frames-13 {
  from {
    transform: translate3d(72vw, 102vh, 0);
  }
  to {
    transform: translate3d(50vw, -108vh, 0);
  }
}
@keyframes move-frames-13 {
  from {
    transform: translate3d(72vw, 102vh, 0);
  }
  to {
    transform: translate3d(50vw, -108vh, 0);
  }
}
.circle-container:nth-child(13) .circle {
  -webkit-animation-delay: 1829ms;
  animation-delay: 1829ms;
}
.circle-container:nth-child(14) {
  width: 6px;
  height: 6px;
  -webkit-animation-name: move-frames-14;
  animation-name: move-frames-14;
  -webkit-animation-duration: 34604ms;
  animation-duration: 34604ms;
  -webkit-animation-delay: 25720ms;
  animation-delay: 25720ms;
}
@-webkit-keyframes move-frames-14 {
  from {
    transform: translate3d(22vw, 106vh, 0);
  }
  to {
    transform: translate3d(79vw, -117vh, 0);
  }
}
@keyframes move-frames-14 {
  from {
    transform: translate3d(22vw, 106vh, 0);
  }
  to {
    transform: translate3d(79vw, -117vh, 0);
  }
}
.circle-container:nth-child(14) .circle {
  -webkit-animation-delay: 2327ms;
  animation-delay: 2327ms;
}
.circle-container:nth-child(15) {
  width: 2px;
  height: 2px;
  -webkit-animation-name: move-frames-15;
  animation-name: move-frames-15;
  -webkit-animation-duration: 35335ms;
  animation-duration: 35335ms;
  -webkit-animation-delay: 15686ms;
  animation-delay: 15686ms;
}
@-webkit-keyframes move-frames-15 {
  from {
    transform: translate3d(37vw, 105vh, 0);
  }
  to {
    transform: translate3d(87vw, -115vh, 0);
  }
}
@keyframes move-frames-15 {
  from {
    transform: translate3d(37vw, 105vh, 0);
  }
  to {
    transform: translate3d(87vw, -115vh, 0);
  }
}
.circle-container:nth-child(15) .circle {
  -webkit-animation-delay: 1196ms;
  animation-delay: 1196ms;
}
.circle-container:nth-child(16) {
  width: 5px;
  height: 5px;
  -webkit-animation-name: move-frames-16;
  animation-name: move-frames-16;
  -webkit-animation-duration: 34670ms;
  animation-duration: 34670ms;
  -webkit-animation-delay: 24637ms;
  animation-delay: 24637ms;
}
@-webkit-keyframes move-frames-16 {
  from {
    transform: translate3d(50vw, 101vh, 0);
  }
  to {
    transform: translate3d(33vw, -106vh, 0);
  }
}
@keyframes move-frames-16 {
  from {
    transform: translate3d(50vw, 101vh, 0);
  }
  to {
    transform: translate3d(33vw, -106vh, 0);
  }
}
.circle-container:nth-child(16) .circle {
  -webkit-animation-delay: 394ms;
  animation-delay: 394ms;
}
.circle-container:nth-child(17) {
  width: 7px;
  height: 7px;
  -webkit-animation-name: move-frames-17;
  animation-name: move-frames-17;
  -webkit-animation-duration: 33099ms;
  animation-duration: 33099ms;
  -webkit-animation-delay: 16898ms;
  animation-delay: 16898ms;
}
@-webkit-keyframes move-frames-17 {
  from {
    transform: translate3d(26vw, 107vh, 0);
  }
  to {
    transform: translate3d(52vw, -111vh, 0);
  }
}
@keyframes move-frames-17 {
  from {
    transform: translate3d(26vw, 107vh, 0);
  }
  to {
    transform: translate3d(52vw, -111vh, 0);
  }
}
.circle-container:nth-child(17) .circle {
  -webkit-animation-delay: 1582ms;
  animation-delay: 1582ms;
}
.circle-container:nth-child(18) {
  width: 1px;
  height: 1px;
  -webkit-animation-name: move-frames-18;
  animation-name: move-frames-18;
  -webkit-animation-duration: 33044ms;
  animation-duration: 33044ms;
  -webkit-animation-delay: 32290ms;
  animation-delay: 32290ms;
}
@-webkit-keyframes move-frames-18 {
  from {
    transform: translate3d(49vw, 106vh, 0);
  }
  to {
    transform: translate3d(70vw, -108vh, 0);
  }
}
@keyframes move-frames-18 {
  from {
    transform: translate3d(49vw, 106vh, 0);
  }
  to {
    transform: translate3d(70vw, -108vh, 0);
  }
}
.circle-container:nth-child(18) .circle {
  -webkit-animation-delay: 2057ms;
  animation-delay: 2057ms;
}
.circle-container:nth-child(19) {
  width: 8px;
  height: 8px;
  -webkit-animation-name: move-frames-19;
  animation-name: move-frames-19;
  -webkit-animation-duration: 30872ms;
  animation-duration: 30872ms;
  -webkit-animation-delay: 21040ms;
  animation-delay: 21040ms;
}
@-webkit-keyframes move-frames-19 {
  from {
    transform: translate3d(78vw, 110vh, 0);
  }
  to {
    transform: translate3d(11vw, -125vh, 0);
  }
}
@keyframes move-frames-19 {
  from {
    transform: translate3d(78vw, 110vh, 0);
  }
  to {
    transform: translate3d(11vw, -125vh, 0);
  }
}
.circle-container:nth-child(19) .circle {
  -webkit-animation-delay: 3109ms;
  animation-delay: 3109ms;
}
.circle-container:nth-child(20) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-20;
  animation-name: move-frames-20;
  -webkit-animation-duration: 31051ms;
  animation-duration: 31051ms;
  -webkit-animation-delay: 11273ms;
  animation-delay: 11273ms;
}
@-webkit-keyframes move-frames-20 {
  from {
    transform: translate3d(39vw, 105vh, 0);
  }
  to {
    transform: translate3d(2vw, -108vh, 0);
  }
}
@keyframes move-frames-20 {
  from {
    transform: translate3d(39vw, 105vh, 0);
  }
  to {
    transform: translate3d(2vw, -108vh, 0);
  }
}
.circle-container:nth-child(20) .circle {
  -webkit-animation-delay: 1325ms;
  animation-delay: 1325ms;
}
.circle-container:nth-child(21) {
  width: 4px;
  height: 4px;
  -webkit-animation-name: move-frames-21;
  animation-name: move-frames-21;
  -webkit-animation-duration: 31185ms;
  animation-duration: 31185ms;
  -webkit-animation-delay: 16684ms;
  animation-delay: 16684ms;
}
@-webkit-keyframes move-frames-21 {
  from {
    transform: translate3d(9vw, 102vh, 0);
  }
  to {
    transform: translate3d(20vw, -106vh, 0);
  }
}
@keyframes move-frames-21 {
  from {
    transform: translate3d(9vw, 102vh, 0);
  }
  to {
    transform: translate3d(20vw, -106vh, 0);
  }
}
.circle-container:nth-child(21) .circle {
  -webkit-animation-delay: 3776ms;
  animation-delay: 3776ms;
}
.circle-container:nth-child(22) {
  width: 6px;
  height: 6px;
  -webkit-animation-name: move-frames-22;
  animation-name: move-frames-22;
  -webkit-animation-duration: 34231ms;
  animation-duration: 34231ms;
  -webkit-animation-delay: 13410ms;
  animation-delay: 13410ms;
}
@-webkit-keyframes move-frames-22 {
  from {
    transform: translate3d(86vw, 105vh, 0);
  }
  to {
    transform: translate3d(50vw, -124vh, 0);
  }
}
@keyframes move-frames-22 {
  from {
    transform: translate3d(86vw, 105vh, 0);
  }
  to {
    transform: translate3d(50vw, -124vh, 0);
  }
}
.circle-container:nth-child(22) .circle {
  -webkit-animation-delay: 2030ms;
  animation-delay: 2030ms;
}
.circle-container:nth-child(23) {
  width: 2px;
  height: 2px;
  -webkit-animation-name: move-frames-23;
  animation-name: move-frames-23;
  -webkit-animation-duration: 36546ms;
  animation-duration: 36546ms;
  -webkit-animation-delay: 10419ms;
  animation-delay: 10419ms;
}
@-webkit-keyframes move-frames-23 {
  from {
    transform: translate3d(30vw, 106vh, 0);
  }
  to {
    transform: translate3d(61vw, -119vh, 0);
  }
}
@keyframes move-frames-23 {
  from {
    transform: translate3d(30vw, 106vh, 0);
  }
  to {
    transform: translate3d(61vw, -119vh, 0);
  }
}
.circle-container:nth-child(23) .circle {
  -webkit-animation-delay: 383ms;
  animation-delay: 383ms;
}
.circle-container:nth-child(24) {
  width: 4px;
  height: 4px;
  -webkit-animation-name: move-frames-24;
  animation-name: move-frames-24;
  -webkit-animation-duration: 31834ms;
  animation-duration: 31834ms;
  -webkit-animation-delay: 22496ms;
  animation-delay: 22496ms;
}
@-webkit-keyframes move-frames-24 {
  from {
    transform: translate3d(37vw, 104vh, 0);
  }
  to {
    transform: translate3d(49vw, -122vh, 0);
  }
}
@keyframes move-frames-24 {
  from {
    transform: translate3d(37vw, 104vh, 0);
  }
  to {
    transform: translate3d(49vw, -122vh, 0);
  }
}
.circle-container:nth-child(24) .circle {
  -webkit-animation-delay: 2046ms;
  animation-delay: 2046ms;
}
.circle-container:nth-child(25) {
  width: 5px;
  height: 5px;
  -webkit-animation-name: move-frames-25;
  animation-name: move-frames-25;
  -webkit-animation-duration: 33398ms;
  animation-duration: 33398ms;
  -webkit-animation-delay: 8759ms;
  animation-delay: 8759ms;
}
@-webkit-keyframes move-frames-25 {
  from {
    transform: translate3d(76vw, 105vh, 0);
  }
  to {
    transform: translate3d(72vw, -113vh, 0);
  }
}
@keyframes move-frames-25 {
  from {
    transform: translate3d(76vw, 105vh, 0);
  }
  to {
    transform: translate3d(72vw, -113vh, 0);
  }
}
.circle-container:nth-child(25) .circle {
  -webkit-animation-delay: 2241ms;
  animation-delay: 2241ms;
}
.circle-container:nth-child(26) {
  width: 2px;
  height: 2px;
  -webkit-animation-name: move-frames-26;
  animation-name: move-frames-26;
  -webkit-animation-duration: 34443ms;
  animation-duration: 34443ms;
  -webkit-animation-delay: 3173ms;
  animation-delay: 3173ms;
}
@-webkit-keyframes move-frames-26 {
  from {
    transform: translate3d(17vw, 110vh, 0);
  }
  to {
    transform: translate3d(87vw, -123vh, 0);
  }
}
@keyframes move-frames-26 {
  from {
    transform: translate3d(17vw, 110vh, 0);
  }
  to {
    transform: translate3d(87vw, -123vh, 0);
  }
}
.circle-container:nth-child(26) .circle {
  -webkit-animation-delay: 549ms;
  animation-delay: 549ms;
}
.circle-container:nth-child(27) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-27;
  animation-name: move-frames-27;
  -webkit-animation-duration: 32981ms;
  animation-duration: 32981ms;
  -webkit-animation-delay: 14527ms;
  animation-delay: 14527ms;
}
@-webkit-keyframes move-frames-27 {
  from {
    transform: translate3d(89vw, 108vh, 0);
  }
  to {
    transform: translate3d(27vw, -133vh, 0);
  }
}
@keyframes move-frames-27 {
  from {
    transform: translate3d(89vw, 108vh, 0);
  }
  to {
    transform: translate3d(27vw, -133vh, 0);
  }
}
.circle-container:nth-child(27) .circle {
  -webkit-animation-delay: 956ms;
  animation-delay: 956ms;
}
.circle-container:nth-child(28) {
  width: 5px;
  height: 5px;
  -webkit-animation-name: move-frames-28;
  animation-name: move-frames-28;
  -webkit-animation-duration: 29769ms;
  animation-duration: 29769ms;
  -webkit-animation-delay: 35227ms;
  animation-delay: 35227ms;
}
@-webkit-keyframes move-frames-28 {
  from {
    transform: translate3d(76vw, 105vh, 0);
  }
  to {
    transform: translate3d(65vw, -127vh, 0);
  }
}
@keyframes move-frames-28 {
  from {
    transform: translate3d(76vw, 105vh, 0);
  }
  to {
    transform: translate3d(65vw, -127vh, 0);
  }
}
.circle-container:nth-child(28) .circle {
  -webkit-animation-delay: 2347ms;
  animation-delay: 2347ms;
}
.circle-container:nth-child(29) {
  width: 5px;
  height: 5px;
  -webkit-animation-name: move-frames-29;
  animation-name: move-frames-29;
  -webkit-animation-duration: 35685ms;
  animation-duration: 35685ms;
  -webkit-animation-delay: 9960ms;
  animation-delay: 9960ms;
}
@-webkit-keyframes move-frames-29 {
  from {
    transform: translate3d(96vw, 101vh, 0);
  }
  to {
    transform: translate3d(59vw, -102vh, 0);
  }
}
@keyframes move-frames-29 {
  from {
    transform: translate3d(96vw, 101vh, 0);
  }
  to {
    transform: translate3d(59vw, -102vh, 0);
  }
}
.circle-container:nth-child(29) .circle {
  -webkit-animation-delay: 549ms;
  animation-delay: 549ms;
}
.circle-container:nth-child(30) {
  width: 7px;
  height: 7px;
  -webkit-animation-name: move-frames-30;
  animation-name: move-frames-30;
  -webkit-animation-duration: 29337ms;
  animation-duration: 29337ms;
  -webkit-animation-delay: 23049ms;
  animation-delay: 23049ms;
}
@-webkit-keyframes move-frames-30 {
  from {
    transform: translate3d(30vw, 106vh, 0);
  }
  to {
    transform: translate3d(57vw, -118vh, 0);
  }
}
@keyframes move-frames-30 {
  from {
    transform: translate3d(30vw, 106vh, 0);
  }
  to {
    transform: translate3d(57vw, -118vh, 0);
  }
}
.circle-container:nth-child(30) .circle {
  -webkit-animation-delay: 976ms;
  animation-delay: 976ms;
}
.circle-container:nth-child(31) {
  width: 6px;
  height: 6px;
  -webkit-animation-name: move-frames-31;
  animation-name: move-frames-31;
  -webkit-animation-duration: 33694ms;
  animation-duration: 33694ms;
  -webkit-animation-delay: 7604ms;
  animation-delay: 7604ms;
}
@-webkit-keyframes move-frames-31 {
  from {
    transform: translate3d(48vw, 106vh, 0);
  }
  to {
    transform: translate3d(39vw, -118vh, 0);
  }
}
@keyframes move-frames-31 {
  from {
    transform: translate3d(48vw, 106vh, 0);
  }
  to {
    transform: translate3d(39vw, -118vh, 0);
  }
}
.circle-container:nth-child(31) .circle {
  -webkit-animation-delay: 2614ms;
  animation-delay: 2614ms;
}
.circle-container:nth-child(32) {
  width: 4px;
  height: 4px;
  -webkit-animation-name: move-frames-32;
  animation-name: move-frames-32;
  -webkit-animation-duration: 35041ms;
  animation-duration: 35041ms;
  -webkit-animation-delay: 36576ms;
  animation-delay: 36576ms;
}
@-webkit-keyframes move-frames-32 {
  from {
    transform: translate3d(14vw, 103vh, 0);
  }
  to {
    transform: translate3d(96vw, -112vh, 0);
  }
}
@keyframes move-frames-32 {
  from {
    transform: translate3d(14vw, 103vh, 0);
  }
  to {
    transform: translate3d(96vw, -112vh, 0);
  }
}
.circle-container:nth-child(32) .circle {
  -webkit-animation-delay: 2417ms;
  animation-delay: 2417ms;
}
.circle-container:nth-child(33) {
  width: 2px;
  height: 2px;
  -webkit-animation-name: move-frames-33;
  animation-name: move-frames-33;
  -webkit-animation-duration: 28213ms;
  animation-duration: 28213ms;
  -webkit-animation-delay: 33275ms;
  animation-delay: 33275ms;
}
@-webkit-keyframes move-frames-33 {
  from {
    transform: translate3d(31vw, 102vh, 0);
  }
  to {
    transform: translate3d(74vw, -114vh, 0);
  }
}
@keyframes move-frames-33 {
  from {
    transform: translate3d(31vw, 102vh, 0);
  }
  to {
    transform: translate3d(74vw, -114vh, 0);
  }
}
.circle-container:nth-child(33) .circle {
  -webkit-animation-delay: 618ms;
  animation-delay: 618ms;
}
.circle-container:nth-child(34) {
  width: 6px;
  height: 6px;
  -webkit-animation-name: move-frames-34;
  animation-name: move-frames-34;
  -webkit-animation-duration: 31377ms;
  animation-duration: 31377ms;
  -webkit-animation-delay: 34201ms;
  animation-delay: 34201ms;
}
@-webkit-keyframes move-frames-34 {
  from {
    transform: translate3d(17vw, 103vh, 0);
  }
  to {
    transform: translate3d(46vw, -113vh, 0);
  }
}
@keyframes move-frames-34 {
  from {
    transform: translate3d(17vw, 103vh, 0);
  }
  to {
    transform: translate3d(46vw, -113vh, 0);
  }
}
.circle-container:nth-child(34) .circle {
  -webkit-animation-delay: 3551ms;
  animation-delay: 3551ms;
}
.circle-container:nth-child(35) {
  width: 4px;
  height: 4px;
  -webkit-animation-name: move-frames-35;
  animation-name: move-frames-35;
  -webkit-animation-duration: 32771ms;
  animation-duration: 32771ms;
  -webkit-animation-delay: 26808ms;
  animation-delay: 26808ms;
}
@-webkit-keyframes move-frames-35 {
  from {
    transform: translate3d(5vw, 106vh, 0);
  }
  to {
    transform: translate3d(39vw, -132vh, 0);
  }
}
@keyframes move-frames-35 {
  from {
    transform: translate3d(5vw, 106vh, 0);
  }
  to {
    transform: translate3d(39vw, -132vh, 0);
  }
}
.circle-container:nth-child(35) .circle {
  -webkit-animation-delay: 1541ms;
  animation-delay: 1541ms;
}
.circle-container:nth-child(36) {
  width: 5px;
  height: 5px;
  -webkit-animation-name: move-frames-36;
  animation-name: move-frames-36;
  -webkit-animation-duration: 31739ms;
  animation-duration: 31739ms;
  -webkit-animation-delay: 2307ms;
  animation-delay: 2307ms;
}
@-webkit-keyframes move-frames-36 {
  from {
    transform: translate3d(19vw, 106vh, 0);
  }
  to {
    transform: translate3d(26vw, -131vh, 0);
  }
}
@keyframes move-frames-36 {
  from {
    transform: translate3d(19vw, 106vh, 0);
  }
  to {
    transform: translate3d(26vw, -131vh, 0);
  }
}
.circle-container:nth-child(36) .circle {
  -webkit-animation-delay: 52ms;
  animation-delay: 52ms;
}
.circle-container:nth-child(37) {
  width: 6px;
  height: 6px;
  -webkit-animation-name: move-frames-37;
  animation-name: move-frames-37;
  -webkit-animation-duration: 29563ms;
  animation-duration: 29563ms;
  -webkit-animation-delay: 4210ms;
  animation-delay: 4210ms;
}
@-webkit-keyframes move-frames-37 {
  from {
    transform: translate3d(79vw, 104vh, 0);
  }
  to {
    transform: translate3d(78vw, -123vh, 0);
  }
}
@keyframes move-frames-37 {
  from {
    transform: translate3d(79vw, 104vh, 0);
  }
  to {
    transform: translate3d(78vw, -123vh, 0);
  }
}
.circle-container:nth-child(37) .circle {
  -webkit-animation-delay: 1893ms;
  animation-delay: 1893ms;
}
.circle-container:nth-child(38) {
  width: 5px;
  height: 5px;
  -webkit-animation-name: move-frames-38;
  animation-name: move-frames-38;
  -webkit-animation-duration: 35149ms;
  animation-duration: 35149ms;
  -webkit-animation-delay: 27562ms;
  animation-delay: 27562ms;
}
@-webkit-keyframes move-frames-38 {
  from {
    transform: translate3d(40vw, 105vh, 0);
  }
  to {
    transform: translate3d(82vw, -110vh, 0);
  }
}
@keyframes move-frames-38 {
  from {
    transform: translate3d(40vw, 105vh, 0);
  }
  to {
    transform: translate3d(82vw, -110vh, 0);
  }
}
.circle-container:nth-child(38) .circle {
  -webkit-animation-delay: 443ms;
  animation-delay: 443ms;
}
.circle-container:nth-child(39) {
  width: 4px;
  height: 4px;
  -webkit-animation-name: move-frames-39;
  animation-name: move-frames-39;
  -webkit-animation-duration: 34205ms;
  animation-duration: 34205ms;
  -webkit-animation-delay: 26687ms;
  animation-delay: 26687ms;
}
@-webkit-keyframes move-frames-39 {
  from {
    transform: translate3d(48vw, 103vh, 0);
  }
  to {
    transform: translate3d(68vw, -107vh, 0);
  }
}
@keyframes move-frames-39 {
  from {
    transform: translate3d(48vw, 103vh, 0);
  }
  to {
    transform: translate3d(68vw, -107vh, 0);
  }
}
.circle-container:nth-child(39) .circle {
  -webkit-animation-delay: 2786ms;
  animation-delay: 2786ms;
}
.circle-container:nth-child(40) {
  width: 2px;
  height: 2px;
  -webkit-animation-name: move-frames-40;
  animation-name: move-frames-40;
  -webkit-animation-duration: 28885ms;
  animation-duration: 28885ms;
  -webkit-animation-delay: 1377ms;
  animation-delay: 1377ms;
}
@-webkit-keyframes move-frames-40 {
  from {
    transform: translate3d(18vw, 103vh, 0);
  }
  to {
    transform: translate3d(62vw, -125vh, 0);
  }
}
@keyframes move-frames-40 {
  from {
    transform: translate3d(18vw, 103vh, 0);
  }
  to {
    transform: translate3d(62vw, -125vh, 0);
  }
}
.circle-container:nth-child(40) .circle {
  -webkit-animation-delay: 3565ms;
  animation-delay: 3565ms;
}
.circle-container:nth-child(41) {
  width: 5px;
  height: 5px;
  -webkit-animation-name: move-frames-41;
  animation-name: move-frames-41;
  -webkit-animation-duration: 30397ms;
  animation-duration: 30397ms;
  -webkit-animation-delay: 1438ms;
  animation-delay: 1438ms;
}
@-webkit-keyframes move-frames-41 {
  from {
    transform: translate3d(92vw, 110vh, 0);
  }
  to {
    transform: translate3d(81vw, -112vh, 0);
  }
}
@keyframes move-frames-41 {
  from {
    transform: translate3d(92vw, 110vh, 0);
  }
  to {
    transform: translate3d(81vw, -112vh, 0);
  }
}
.circle-container:nth-child(41) .circle {
  -webkit-animation-delay: 2639ms;
  animation-delay: 2639ms;
}
.circle-container:nth-child(42) {
  width: 4px;
  height: 4px;
  -webkit-animation-name: move-frames-42;
  animation-name: move-frames-42;
  -webkit-animation-duration: 32560ms;
  animation-duration: 32560ms;
  -webkit-animation-delay: 33327ms;
  animation-delay: 33327ms;
}
@-webkit-keyframes move-frames-42 {
  from {
    transform: translate3d(78vw, 110vh, 0);
  }
  to {
    transform: translate3d(30vw, -133vh, 0);
  }
}
@keyframes move-frames-42 {
  from {
    transform: translate3d(78vw, 110vh, 0);
  }
  to {
    transform: translate3d(30vw, -133vh, 0);
  }
}
.circle-container:nth-child(42) .circle {
  -webkit-animation-delay: 2166ms;
  animation-delay: 2166ms;
}
.circle-container:nth-child(43) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-43;
  animation-name: move-frames-43;
  -webkit-animation-duration: 35638ms;
  animation-duration: 35638ms;
  -webkit-animation-delay: 13331ms;
  animation-delay: 13331ms;
}
@-webkit-keyframes move-frames-43 {
  from {
    transform: translate3d(56vw, 110vh, 0);
  }
  to {
    transform: translate3d(87vw, -138vh, 0);
  }
}
@keyframes move-frames-43 {
  from {
    transform: translate3d(56vw, 110vh, 0);
  }
  to {
    transform: translate3d(87vw, -138vh, 0);
  }
}
.circle-container:nth-child(43) .circle {
  -webkit-animation-delay: 379ms;
  animation-delay: 379ms;
}
.circle-container:nth-child(44) {
  width: 5px;
  height: 5px;
  -webkit-animation-name: move-frames-44;
  animation-name: move-frames-44;
  -webkit-animation-duration: 35734ms;
  animation-duration: 35734ms;
  -webkit-animation-delay: 4804ms;
  animation-delay: 4804ms;
}
@-webkit-keyframes move-frames-44 {
  from {
    transform: translate3d(83vw, 106vh, 0);
  }
  to {
    transform: translate3d(58vw, -126vh, 0);
  }
}
@keyframes move-frames-44 {
  from {
    transform: translate3d(83vw, 106vh, 0);
  }
  to {
    transform: translate3d(58vw, -126vh, 0);
  }
}
.circle-container:nth-child(44) .circle {
  -webkit-animation-delay: 858ms;
  animation-delay: 858ms;
}
.circle-container:nth-child(45) {
  width: 4px;
  height: 4px;
  -webkit-animation-name: move-frames-45;
  animation-name: move-frames-45;
  -webkit-animation-duration: 36063ms;
  animation-duration: 36063ms;
  -webkit-animation-delay: 13156ms;
  animation-delay: 13156ms;
}
@-webkit-keyframes move-frames-45 {
  from {
    transform: translate3d(85vw, 103vh, 0);
  }
  to {
    transform: translate3d(59vw, -133vh, 0);
  }
}
@keyframes move-frames-45 {
  from {
    transform: translate3d(85vw, 103vh, 0);
  }
  to {
    transform: translate3d(59vw, -133vh, 0);
  }
}
.circle-container:nth-child(45) .circle {
  -webkit-animation-delay: 883ms;
  animation-delay: 883ms;
}
.circle-container:nth-child(46) {
  width: 5px;
  height: 5px;
  -webkit-animation-name: move-frames-46;
  animation-name: move-frames-46;
  -webkit-animation-duration: 29002ms;
  animation-duration: 29002ms;
  -webkit-animation-delay: 10075ms;
  animation-delay: 10075ms;
}
@-webkit-keyframes move-frames-46 {
  from {
    transform: translate3d(79vw, 104vh, 0);
  }
  to {
    transform: translate3d(51vw, -109vh, 0);
  }
}
@keyframes move-frames-46 {
  from {
    transform: translate3d(79vw, 104vh, 0);
  }
  to {
    transform: translate3d(51vw, -109vh, 0);
  }
}
.circle-container:nth-child(46) .circle {
  -webkit-animation-delay: 3998ms;
  animation-delay: 3998ms;
}
.circle-container:nth-child(47) {
  width: 8px;
  height: 8px;
  -webkit-animation-name: move-frames-47;
  animation-name: move-frames-47;
  -webkit-animation-duration: 36894ms;
  animation-duration: 36894ms;
  -webkit-animation-delay: 17210ms;
  animation-delay: 17210ms;
}
@-webkit-keyframes move-frames-47 {
  from {
    transform: translate3d(91vw, 101vh, 0);
  }
  to {
    transform: translate3d(6vw, -130vh, 0);
  }
}
@keyframes move-frames-47 {
  from {
    transform: translate3d(91vw, 101vh, 0);
  }
  to {
    transform: translate3d(6vw, -130vh, 0);
  }
}
.circle-container:nth-child(47) .circle {
  -webkit-animation-delay: 870ms;
  animation-delay: 870ms;
}
.circle-container:nth-child(48) {
  width: 8px;
  height: 8px;
  -webkit-animation-name: move-frames-48;
  animation-name: move-frames-48;
  -webkit-animation-duration: 33948ms;
  animation-duration: 33948ms;
  -webkit-animation-delay: 16624ms;
  animation-delay: 16624ms;
}
@-webkit-keyframes move-frames-48 {
  from {
    transform: translate3d(44vw, 105vh, 0);
  }
  to {
    transform: translate3d(39vw, -113vh, 0);
  }
}
@keyframes move-frames-48 {
  from {
    transform: translate3d(44vw, 105vh, 0);
  }
  to {
    transform: translate3d(39vw, -113vh, 0);
  }
}
.circle-container:nth-child(48) .circle {
  -webkit-animation-delay: 2996ms;
  animation-delay: 2996ms;
}
.circle-container:nth-child(49) {
  width: 8px;
  height: 8px;
  -webkit-animation-name: move-frames-49;
  animation-name: move-frames-49;
  -webkit-animation-duration: 35231ms;
  animation-duration: 35231ms;
  -webkit-animation-delay: 27392ms;
  animation-delay: 27392ms;
}
@-webkit-keyframes move-frames-49 {
  from {
    transform: translate3d(37vw, 106vh, 0);
  }
  to {
    transform: translate3d(6vw, -107vh, 0);
  }
}
@keyframes move-frames-49 {
  from {
    transform: translate3d(37vw, 106vh, 0);
  }
  to {
    transform: translate3d(6vw, -107vh, 0);
  }
}
.circle-container:nth-child(49) .circle {
  -webkit-animation-delay: 3033ms;
  animation-delay: 3033ms;
}
.circle-container:nth-child(50) {
  width: 6px;
  height: 6px;
  -webkit-animation-name: move-frames-50;
  animation-name: move-frames-50;
  -webkit-animation-duration: 28571ms;
  animation-duration: 28571ms;
  -webkit-animation-delay: 28424ms;
  animation-delay: 28424ms;
}
@-webkit-keyframes move-frames-50 {
  from {
    transform: translate3d(5vw, 107vh, 0);
  }
  to {
    transform: translate3d(15vw, -110vh, 0);
  }
}
@keyframes move-frames-50 {
  from {
    transform: translate3d(5vw, 107vh, 0);
  }
  to {
    transform: translate3d(15vw, -110vh, 0);
  }
}
.circle-container:nth-child(50) .circle {
  -webkit-animation-delay: 3717ms;
  animation-delay: 3717ms;
}
.circle-container:nth-child(51) {
  width: 2px;
  height: 2px;
  -webkit-animation-name: move-frames-51;
  animation-name: move-frames-51;
  -webkit-animation-duration: 36870ms;
  animation-duration: 36870ms;
  -webkit-animation-delay: 36797ms;
  animation-delay: 36797ms;
}
@-webkit-keyframes move-frames-51 {
  from {
    transform: translate3d(46vw, 106vh, 0);
  }
  to {
    transform: translate3d(12vw, -111vh, 0);
  }
}
@keyframes move-frames-51 {
  from {
    transform: translate3d(46vw, 106vh, 0);
  }
  to {
    transform: translate3d(12vw, -111vh, 0);
  }
}
.circle-container:nth-child(51) .circle {
  -webkit-animation-delay: 68ms;
  animation-delay: 68ms;
}
.circle-container:nth-child(52) {
  width: 8px;
  height: 8px;
  -webkit-animation-name: move-frames-52;
  animation-name: move-frames-52;
  -webkit-animation-duration: 29079ms;
  animation-duration: 29079ms;
  -webkit-animation-delay: 33752ms;
  animation-delay: 33752ms;
}
@-webkit-keyframes move-frames-52 {
  from {
    transform: translate3d(16vw, 103vh, 0);
  }
  to {
    transform: translate3d(91vw, -130vh, 0);
  }
}
@keyframes move-frames-52 {
  from {
    transform: translate3d(16vw, 103vh, 0);
  }
  to {
    transform: translate3d(91vw, -130vh, 0);
  }
}
.circle-container:nth-child(52) .circle {
  -webkit-animation-delay: 2265ms;
  animation-delay: 2265ms;
}
.circle-container:nth-child(53) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-53;
  animation-name: move-frames-53;
  -webkit-animation-duration: 30895ms;
  animation-duration: 30895ms;
  -webkit-animation-delay: 1485ms;
  animation-delay: 1485ms;
}
@-webkit-keyframes move-frames-53 {
  from {
    transform: translate3d(9vw, 110vh, 0);
  }
  to {
    transform: translate3d(32vw, -133vh, 0);
  }
}
@keyframes move-frames-53 {
  from {
    transform: translate3d(9vw, 110vh, 0);
  }
  to {
    transform: translate3d(32vw, -133vh, 0);
  }
}
.circle-container:nth-child(53) .circle {
  -webkit-animation-delay: 1ms;
  animation-delay: 1ms;
}
.circle-container:nth-child(54) {
  width: 7px;
  height: 7px;
  -webkit-animation-name: move-frames-54;
  animation-name: move-frames-54;
  -webkit-animation-duration: 31173ms;
  animation-duration: 31173ms;
  -webkit-animation-delay: 12312ms;
  animation-delay: 12312ms;
}
@-webkit-keyframes move-frames-54 {
  from {
    transform: translate3d(61vw, 108vh, 0);
  }
  to {
    transform: translate3d(17vw, -110vh, 0);
  }
}
@keyframes move-frames-54 {
  from {
    transform: translate3d(61vw, 108vh, 0);
  }
  to {
    transform: translate3d(17vw, -110vh, 0);
  }
}
.circle-container:nth-child(54) .circle {
  -webkit-animation-delay: 2744ms;
  animation-delay: 2744ms;
}
.circle-container:nth-child(55) {
  width: 8px;
  height: 8px;
  -webkit-animation-name: move-frames-55;
  animation-name: move-frames-55;
  -webkit-animation-duration: 30675ms;
  animation-duration: 30675ms;
  -webkit-animation-delay: 6216ms;
  animation-delay: 6216ms;
}
@-webkit-keyframes move-frames-55 {
  from {
    transform: translate3d(60vw, 104vh, 0);
  }
  to {
    transform: translate3d(93vw, -124vh, 0);
  }
}
@keyframes move-frames-55 {
  from {
    transform: translate3d(60vw, 104vh, 0);
  }
  to {
    transform: translate3d(93vw, -124vh, 0);
  }
}
.circle-container:nth-child(55) .circle {
  -webkit-animation-delay: 3154ms;
  animation-delay: 3154ms;
}
.circle-container:nth-child(56) {
  width: 1px;
  height: 1px;
  -webkit-animation-name: move-frames-56;
  animation-name: move-frames-56;
  -webkit-animation-duration: 29508ms;
  animation-duration: 29508ms;
  -webkit-animation-delay: 32405ms;
  animation-delay: 32405ms;
}
@-webkit-keyframes move-frames-56 {
  from {
    transform: translate3d(72vw, 105vh, 0);
  }
  to {
    transform: translate3d(85vw, -121vh, 0);
  }
}
@keyframes move-frames-56 {
  from {
    transform: translate3d(72vw, 105vh, 0);
  }
  to {
    transform: translate3d(85vw, -121vh, 0);
  }
}
.circle-container:nth-child(56) .circle {
  -webkit-animation-delay: 2993ms;
  animation-delay: 2993ms;
}
.circle-container:nth-child(57) {
  width: 6px;
  height: 6px;
  -webkit-animation-name: move-frames-57;
  animation-name: move-frames-57;
  -webkit-animation-duration: 34852ms;
  animation-duration: 34852ms;
  -webkit-animation-delay: 11147ms;
  animation-delay: 11147ms;
}
@-webkit-keyframes move-frames-57 {
  from {
    transform: translate3d(78vw, 108vh, 0);
  }
  to {
    transform: translate3d(61vw, -125vh, 0);
  }
}
@keyframes move-frames-57 {
  from {
    transform: translate3d(78vw, 108vh, 0);
  }
  to {
    transform: translate3d(61vw, -125vh, 0);
  }
}
.circle-container:nth-child(57) .circle {
  -webkit-animation-delay: 1033ms;
  animation-delay: 1033ms;
}
.circle-container:nth-child(58) {
  width: 5px;
  height: 5px;
  -webkit-animation-name: move-frames-58;
  animation-name: move-frames-58;
  -webkit-animation-duration: 35997ms;
  animation-duration: 35997ms;
  -webkit-animation-delay: 20849ms;
  animation-delay: 20849ms;
}
@-webkit-keyframes move-frames-58 {
  from {
    transform: translate3d(76vw, 109vh, 0);
  }
  to {
    transform: translate3d(47vw, -110vh, 0);
  }
}
@keyframes move-frames-58 {
  from {
    transform: translate3d(76vw, 109vh, 0);
  }
  to {
    transform: translate3d(47vw, -110vh, 0);
  }
}
.circle-container:nth-child(58) .circle {
  -webkit-animation-delay: 776ms;
  animation-delay: 776ms;
}
.circle-container:nth-child(59) {
  width: 1px;
  height: 1px;
  -webkit-animation-name: move-frames-59;
  animation-name: move-frames-59;
  -webkit-animation-duration: 31161ms;
  animation-duration: 31161ms;
  -webkit-animation-delay: 36102ms;
  animation-delay: 36102ms;
}
@-webkit-keyframes move-frames-59 {
  from {
    transform: translate3d(35vw, 107vh, 0);
  }
  to {
    transform: translate3d(29vw, -124vh, 0);
  }
}
@keyframes move-frames-59 {
  from {
    transform: translate3d(35vw, 107vh, 0);
  }
  to {
    transform: translate3d(29vw, -124vh, 0);
  }
}
.circle-container:nth-child(59) .circle {
  -webkit-animation-delay: 946ms;
  animation-delay: 946ms;
}
.circle-container:nth-child(60) {
  width: 8px;
  height: 8px;
  -webkit-animation-name: move-frames-60;
  animation-name: move-frames-60;
  -webkit-animation-duration: 31974ms;
  animation-duration: 31974ms;
  -webkit-animation-delay: 25372ms;
  animation-delay: 25372ms;
}
@-webkit-keyframes move-frames-60 {
  from {
    transform: translate3d(6vw, 108vh, 0);
  }
  to {
    transform: translate3d(12vw, -109vh, 0);
  }
}
@keyframes move-frames-60 {
  from {
    transform: translate3d(6vw, 108vh, 0);
  }
  to {
    transform: translate3d(12vw, -109vh, 0);
  }
}
.circle-container:nth-child(60) .circle {
  -webkit-animation-delay: 1515ms;
  animation-delay: 1515ms;
}
.circle-container:nth-child(61) {
  width: 7px;
  height: 7px;
  -webkit-animation-name: move-frames-61;
  animation-name: move-frames-61;
  -webkit-animation-duration: 36103ms;
  animation-duration: 36103ms;
  -webkit-animation-delay: 8588ms;
  animation-delay: 8588ms;
}
@-webkit-keyframes move-frames-61 {
  from {
    transform: translate3d(26vw, 110vh, 0);
  }
  to {
    transform: translate3d(54vw, -123vh, 0);
  }
}
@keyframes move-frames-61 {
  from {
    transform: translate3d(26vw, 110vh, 0);
  }
  to {
    transform: translate3d(54vw, -123vh, 0);
  }
}
.circle-container:nth-child(61) .circle {
  -webkit-animation-delay: 21ms;
  animation-delay: 21ms;
}
.circle-container:nth-child(62) {
  width: 7px;
  height: 7px;
  -webkit-animation-name: move-frames-62;
  animation-name: move-frames-62;
  -webkit-animation-duration: 31795ms;
  animation-duration: 31795ms;
  -webkit-animation-delay: 3494ms;
  animation-delay: 3494ms;
}
@-webkit-keyframes move-frames-62 {
  from {
    transform: translate3d(93vw, 108vh, 0);
  }
  to {
    transform: translate3d(78vw, -113vh, 0);
  }
}
@keyframes move-frames-62 {
  from {
    transform: translate3d(93vw, 108vh, 0);
  }
  to {
    transform: translate3d(78vw, -113vh, 0);
  }
}
.circle-container:nth-child(62) .circle {
  -webkit-animation-delay: 3588ms;
  animation-delay: 3588ms;
}
.circle-container:nth-child(63) {
  width: 7px;
  height: 7px;
  -webkit-animation-name: move-frames-63;
  animation-name: move-frames-63;
  -webkit-animation-duration: 32458ms;
  animation-duration: 32458ms;
  -webkit-animation-delay: 36704ms;
  animation-delay: 36704ms;
}
@-webkit-keyframes move-frames-63 {
  from {
    transform: translate3d(46vw, 108vh, 0);
  }
  to {
    transform: translate3d(49vw, -126vh, 0);
  }
}
@keyframes move-frames-63 {
  from {
    transform: translate3d(46vw, 108vh, 0);
  }
  to {
    transform: translate3d(49vw, -126vh, 0);
  }
}
.circle-container:nth-child(63) .circle {
  -webkit-animation-delay: 2446ms;
  animation-delay: 2446ms;
}
.circle-container:nth-child(64) {
  width: 2px;
  height: 2px;
  -webkit-animation-name: move-frames-64;
  animation-name: move-frames-64;
  -webkit-animation-duration: 31497ms;
  animation-duration: 31497ms;
  -webkit-animation-delay: 32436ms;
  animation-delay: 32436ms;
}
@-webkit-keyframes move-frames-64 {
  from {
    transform: translate3d(59vw, 103vh, 0);
  }
  to {
    transform: translate3d(73vw, -104vh, 0);
  }
}
@keyframes move-frames-64 {
  from {
    transform: translate3d(59vw, 103vh, 0);
  }
  to {
    transform: translate3d(73vw, -104vh, 0);
  }
}
.circle-container:nth-child(64) .circle {
  -webkit-animation-delay: 2741ms;
  animation-delay: 2741ms;
}
.circle-container:nth-child(65) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-65;
  animation-name: move-frames-65;
  -webkit-animation-duration: 33563ms;
  animation-duration: 33563ms;
  -webkit-animation-delay: 15154ms;
  animation-delay: 15154ms;
}
@-webkit-keyframes move-frames-65 {
  from {
    transform: translate3d(75vw, 102vh, 0);
  }
  to {
    transform: translate3d(50vw, -129vh, 0);
  }
}
@keyframes move-frames-65 {
  from {
    transform: translate3d(75vw, 102vh, 0);
  }
  to {
    transform: translate3d(50vw, -129vh, 0);
  }
}
.circle-container:nth-child(65) .circle {
  -webkit-animation-delay: 319ms;
  animation-delay: 319ms;
}
.circle-container:nth-child(66) {
  width: 1px;
  height: 1px;
  -webkit-animation-name: move-frames-66;
  animation-name: move-frames-66;
  -webkit-animation-duration: 35314ms;
  animation-duration: 35314ms;
  -webkit-animation-delay: 10184ms;
  animation-delay: 10184ms;
}
@-webkit-keyframes move-frames-66 {
  from {
    transform: translate3d(87vw, 107vh, 0);
  }
  to {
    transform: translate3d(85vw, -136vh, 0);
  }
}
@keyframes move-frames-66 {
  from {
    transform: translate3d(87vw, 107vh, 0);
  }
  to {
    transform: translate3d(85vw, -136vh, 0);
  }
}
.circle-container:nth-child(66) .circle {
  -webkit-animation-delay: 2637ms;
  animation-delay: 2637ms;
}
.circle-container:nth-child(67) {
  width: 1px;
  height: 1px;
  -webkit-animation-name: move-frames-67;
  animation-name: move-frames-67;
  -webkit-animation-duration: 33518ms;
  animation-duration: 33518ms;
  -webkit-animation-delay: 5463ms;
  animation-delay: 5463ms;
}
@-webkit-keyframes move-frames-67 {
  from {
    transform: translate3d(55vw, 105vh, 0);
  }
  to {
    transform: translate3d(62vw, -117vh, 0);
  }
}
@keyframes move-frames-67 {
  from {
    transform: translate3d(55vw, 105vh, 0);
  }
  to {
    transform: translate3d(62vw, -117vh, 0);
  }
}
.circle-container:nth-child(67) .circle {
  -webkit-animation-delay: 2718ms;
  animation-delay: 2718ms;
}
.circle-container:nth-child(68) {
  width: 1px;
  height: 1px;
  -webkit-animation-name: move-frames-68;
  animation-name: move-frames-68;
  -webkit-animation-duration: 32128ms;
  animation-duration: 32128ms;
  -webkit-animation-delay: 12701ms;
  animation-delay: 12701ms;
}
@-webkit-keyframes move-frames-68 {
  from {
    transform: translate3d(29vw, 104vh, 0);
  }
  to {
    transform: translate3d(22vw, -118vh, 0);
  }
}
@keyframes move-frames-68 {
  from {
    transform: translate3d(29vw, 104vh, 0);
  }
  to {
    transform: translate3d(22vw, -118vh, 0);
  }
}
.circle-container:nth-child(68) .circle {
  -webkit-animation-delay: 3121ms;
  animation-delay: 3121ms;
}
.circle-container:nth-child(69) {
  width: 6px;
  height: 6px;
  -webkit-animation-name: move-frames-69;
  animation-name: move-frames-69;
  -webkit-animation-duration: 29863ms;
  animation-duration: 29863ms;
  -webkit-animation-delay: 21112ms;
  animation-delay: 21112ms;
}
@-webkit-keyframes move-frames-69 {
  from {
    transform: translate3d(20vw, 103vh, 0);
  }
  to {
    transform: translate3d(93vw, -105vh, 0);
  }
}
@keyframes move-frames-69 {
  from {
    transform: translate3d(20vw, 103vh, 0);
  }
  to {
    transform: translate3d(93vw, -105vh, 0);
  }
}
.circle-container:nth-child(69) .circle {
  -webkit-animation-delay: 901ms;
  animation-delay: 901ms;
}
.circle-container:nth-child(70) {
  width: 2px;
  height: 2px;
  -webkit-animation-name: move-frames-70;
  animation-name: move-frames-70;
  -webkit-animation-duration: 31360ms;
  animation-duration: 31360ms;
  -webkit-animation-delay: 22623ms;
  animation-delay: 22623ms;
}
@-webkit-keyframes move-frames-70 {
  from {
    transform: translate3d(7vw, 106vh, 0);
  }
  to {
    transform: translate3d(100vw, -136vh, 0);
  }
}
@keyframes move-frames-70 {
  from {
    transform: translate3d(7vw, 106vh, 0);
  }
  to {
    transform: translate3d(100vw, -136vh, 0);
  }
}
.circle-container:nth-child(70) .circle {
  -webkit-animation-delay: 3051ms;
  animation-delay: 3051ms;
}
.circle-container:nth-child(71) {
  width: 6px;
  height: 6px;
  -webkit-animation-name: move-frames-71;
  animation-name: move-frames-71;
  -webkit-animation-duration: 34649ms;
  animation-duration: 34649ms;
  -webkit-animation-delay: 6166ms;
  animation-delay: 6166ms;
}
@-webkit-keyframes move-frames-71 {
  from {
    transform: translate3d(6vw, 110vh, 0);
  }
  to {
    transform: translate3d(66vw, -116vh, 0);
  }
}
@keyframes move-frames-71 {
  from {
    transform: translate3d(6vw, 110vh, 0);
  }
  to {
    transform: translate3d(66vw, -116vh, 0);
  }
}
.circle-container:nth-child(71) .circle {
  -webkit-animation-delay: 2427ms;
  animation-delay: 2427ms;
}
.circle-container:nth-child(72) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-72;
  animation-name: move-frames-72;
  -webkit-animation-duration: 34733ms;
  animation-duration: 34733ms;
  -webkit-animation-delay: 24380ms;
  animation-delay: 24380ms;
}
@-webkit-keyframes move-frames-72 {
  from {
    transform: translate3d(18vw, 103vh, 0);
  }
  to {
    transform: translate3d(10vw, -114vh, 0);
  }
}
@keyframes move-frames-72 {
  from {
    transform: translate3d(18vw, 103vh, 0);
  }
  to {
    transform: translate3d(10vw, -114vh, 0);
  }
}
.circle-container:nth-child(72) .circle {
  -webkit-animation-delay: 397ms;
  animation-delay: 397ms;
}
.circle-container:nth-child(73) {
  width: 6px;
  height: 6px;
  -webkit-animation-name: move-frames-73;
  animation-name: move-frames-73;
  -webkit-animation-duration: 29310ms;
  animation-duration: 29310ms;
  -webkit-animation-delay: 12600ms;
  animation-delay: 12600ms;
}
@-webkit-keyframes move-frames-73 {
  from {
    transform: translate3d(58vw, 102vh, 0);
  }
  to {
    transform: translate3d(68vw, -122vh, 0);
  }
}
@keyframes move-frames-73 {
  from {
    transform: translate3d(58vw, 102vh, 0);
  }
  to {
    transform: translate3d(68vw, -122vh, 0);
  }
}
.circle-container:nth-child(73) .circle {
  -webkit-animation-delay: 1728ms;
  animation-delay: 1728ms;
}
.circle-container:nth-child(74) {
  width: 6px;
  height: 6px;
  -webkit-animation-name: move-frames-74;
  animation-name: move-frames-74;
  -webkit-animation-duration: 34116ms;
  animation-duration: 34116ms;
  -webkit-animation-delay: 21175ms;
  animation-delay: 21175ms;
}
@-webkit-keyframes move-frames-74 {
  from {
    transform: translate3d(28vw, 109vh, 0);
  }
  to {
    transform: translate3d(71vw, -133vh, 0);
  }
}
@keyframes move-frames-74 {
  from {
    transform: translate3d(28vw, 109vh, 0);
  }
  to {
    transform: translate3d(71vw, -133vh, 0);
  }
}
.circle-container:nth-child(74) .circle {
  -webkit-animation-delay: 245ms;
  animation-delay: 245ms;
}
.circle-container:nth-child(75) {
  width: 1px;
  height: 1px;
  -webkit-animation-name: move-frames-75;
  animation-name: move-frames-75;
  -webkit-animation-duration: 36640ms;
  animation-duration: 36640ms;
  -webkit-animation-delay: 15533ms;
  animation-delay: 15533ms;
}
@-webkit-keyframes move-frames-75 {
  from {
    transform: translate3d(62vw, 101vh, 0);
  }
  to {
    transform: translate3d(8vw, -131vh, 0);
  }
}
@keyframes move-frames-75 {
  from {
    transform: translate3d(62vw, 101vh, 0);
  }
  to {
    transform: translate3d(8vw, -131vh, 0);
  }
}
.circle-container:nth-child(75) .circle {
  -webkit-animation-delay: 3538ms;
  animation-delay: 3538ms;
}
.circle-container:nth-child(76) {
  width: 1px;
  height: 1px;
  -webkit-animation-name: move-frames-76;
  animation-name: move-frames-76;
  -webkit-animation-duration: 32399ms;
  animation-duration: 32399ms;
  -webkit-animation-delay: 30567ms;
  animation-delay: 30567ms;
}
@-webkit-keyframes move-frames-76 {
  from {
    transform: translate3d(73vw, 101vh, 0);
  }
  to {
    transform: translate3d(51vw, -116vh, 0);
  }
}
@keyframes move-frames-76 {
  from {
    transform: translate3d(73vw, 101vh, 0);
  }
  to {
    transform: translate3d(51vw, -116vh, 0);
  }
}
.circle-container:nth-child(76) .circle {
  -webkit-animation-delay: 394ms;
  animation-delay: 394ms;
}
.circle-container:nth-child(77) {
  width: 8px;
  height: 8px;
  -webkit-animation-name: move-frames-77;
  animation-name: move-frames-77;
  -webkit-animation-duration: 36664ms;
  animation-duration: 36664ms;
  -webkit-animation-delay: 20825ms;
  animation-delay: 20825ms;
}
@-webkit-keyframes move-frames-77 {
  from {
    transform: translate3d(80vw, 103vh, 0);
  }
  to {
    transform: translate3d(4vw, -106vh, 0);
  }
}
@keyframes move-frames-77 {
  from {
    transform: translate3d(80vw, 103vh, 0);
  }
  to {
    transform: translate3d(4vw, -106vh, 0);
  }
}
.circle-container:nth-child(77) .circle {
  -webkit-animation-delay: 1827ms;
  animation-delay: 1827ms;
}
.circle-container:nth-child(78) {
  width: 8px;
  height: 8px;
  -webkit-animation-name: move-frames-78;
  animation-name: move-frames-78;
  -webkit-animation-duration: 34053ms;
  animation-duration: 34053ms;
  -webkit-animation-delay: 932ms;
  animation-delay: 932ms;
}
@-webkit-keyframes move-frames-78 {
  from {
    transform: translate3d(77vw, 103vh, 0);
  }
  to {
    transform: translate3d(83vw, -105vh, 0);
  }
}
@keyframes move-frames-78 {
  from {
    transform: translate3d(77vw, 103vh, 0);
  }
  to {
    transform: translate3d(83vw, -105vh, 0);
  }
}
.circle-container:nth-child(78) .circle {
  -webkit-animation-delay: 1234ms;
  animation-delay: 1234ms;
}
.circle-container:nth-child(79) {
  width: 2px;
  height: 2px;
  -webkit-animation-name: move-frames-79;
  animation-name: move-frames-79;
  -webkit-animation-duration: 36632ms;
  animation-duration: 36632ms;
  -webkit-animation-delay: 24944ms;
  animation-delay: 24944ms;
}
@-webkit-keyframes move-frames-79 {
  from {
    transform: translate3d(72vw, 106vh, 0);
  }
  to {
    transform: translate3d(4vw, -120vh, 0);
  }
}
@keyframes move-frames-79 {
  from {
    transform: translate3d(72vw, 106vh, 0);
  }
  to {
    transform: translate3d(4vw, -120vh, 0);
  }
}
.circle-container:nth-child(79) .circle {
  -webkit-animation-delay: 3576ms;
  animation-delay: 3576ms;
}
.circle-container:nth-child(80) {
  width: 4px;
  height: 4px;
  -webkit-animation-name: move-frames-80;
  animation-name: move-frames-80;
  -webkit-animation-duration: 31154ms;
  animation-duration: 31154ms;
  -webkit-animation-delay: 22807ms;
  animation-delay: 22807ms;
}
@-webkit-keyframes move-frames-80 {
  from {
    transform: translate3d(63vw, 101vh, 0);
  }
  to {
    transform: translate3d(76vw, -129vh, 0);
  }
}
@keyframes move-frames-80 {
  from {
    transform: translate3d(63vw, 101vh, 0);
  }
  to {
    transform: translate3d(76vw, -129vh, 0);
  }
}
.circle-container:nth-child(80) .circle {
  -webkit-animation-delay: 1107ms;
  animation-delay: 1107ms;
}
.circle-container:nth-child(81) {
  width: 5px;
  height: 5px;
  -webkit-animation-name: move-frames-81;
  animation-name: move-frames-81;
  -webkit-animation-duration: 30537ms;
  animation-duration: 30537ms;
  -webkit-animation-delay: 8643ms;
  animation-delay: 8643ms;
}
@-webkit-keyframes move-frames-81 {
  from {
    transform: translate3d(48vw, 103vh, 0);
  }
  to {
    transform: translate3d(65vw, -125vh, 0);
  }
}
@keyframes move-frames-81 {
  from {
    transform: translate3d(48vw, 103vh, 0);
  }
  to {
    transform: translate3d(65vw, -125vh, 0);
  }
}
.circle-container:nth-child(81) .circle {
  -webkit-animation-delay: 1352ms;
  animation-delay: 1352ms;
}
.circle-container:nth-child(82) {
  width: 7px;
  height: 7px;
  -webkit-animation-name: move-frames-82;
  animation-name: move-frames-82;
  -webkit-animation-duration: 33371ms;
  animation-duration: 33371ms;
  -webkit-animation-delay: 31864ms;
  animation-delay: 31864ms;
}
@-webkit-keyframes move-frames-82 {
  from {
    transform: translate3d(37vw, 104vh, 0);
  }
  to {
    transform: translate3d(94vw, -117vh, 0);
  }
}
@keyframes move-frames-82 {
  from {
    transform: translate3d(37vw, 104vh, 0);
  }
  to {
    transform: translate3d(94vw, -117vh, 0);
  }
}
.circle-container:nth-child(82) .circle {
  -webkit-animation-delay: 2663ms;
  animation-delay: 2663ms;
}
.circle-container:nth-child(83) {
  width: 6px;
  height: 6px;
  -webkit-animation-name: move-frames-83;
  animation-name: move-frames-83;
  -webkit-animation-duration: 29343ms;
  animation-duration: 29343ms;
  -webkit-animation-delay: 36415ms;
  animation-delay: 36415ms;
}
@-webkit-keyframes move-frames-83 {
  from {
    transform: translate3d(5vw, 102vh, 0);
  }
  to {
    transform: translate3d(73vw, -132vh, 0);
  }
}
@keyframes move-frames-83 {
  from {
    transform: translate3d(5vw, 102vh, 0);
  }
  to {
    transform: translate3d(73vw, -132vh, 0);
  }
}
.circle-container:nth-child(83) .circle {
  -webkit-animation-delay: 743ms;
  animation-delay: 743ms;
}
.circle-container:nth-child(84) {
  width: 7px;
  height: 7px;
  -webkit-animation-name: move-frames-84;
  animation-name: move-frames-84;
  -webkit-animation-duration: 34707ms;
  animation-duration: 34707ms;
  -webkit-animation-delay: 1592ms;
  animation-delay: 1592ms;
}
@-webkit-keyframes move-frames-84 {
  from {
    transform: translate3d(35vw, 103vh, 0);
  }
  to {
    transform: translate3d(90vw, -127vh, 0);
  }
}
@keyframes move-frames-84 {
  from {
    transform: translate3d(35vw, 103vh, 0);
  }
  to {
    transform: translate3d(90vw, -127vh, 0);
  }
}
.circle-container:nth-child(84) .circle {
  -webkit-animation-delay: 173ms;
  animation-delay: 173ms;
}
.circle-container:nth-child(85) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-85;
  animation-name: move-frames-85;
  -webkit-animation-duration: 28263ms;
  animation-duration: 28263ms;
  -webkit-animation-delay: 30518ms;
  animation-delay: 30518ms;
}
@-webkit-keyframes move-frames-85 {
  from {
    transform: translate3d(24vw, 104vh, 0);
  }
  to {
    transform: translate3d(80vw, -106vh, 0);
  }
}
@keyframes move-frames-85 {
  from {
    transform: translate3d(24vw, 104vh, 0);
  }
  to {
    transform: translate3d(80vw, -106vh, 0);
  }
}
.circle-container:nth-child(85) .circle {
  -webkit-animation-delay: 2475ms;
  animation-delay: 2475ms;
}
.circle-container:nth-child(86) {
  width: 7px;
  height: 7px;
  -webkit-animation-name: move-frames-86;
  animation-name: move-frames-86;
  -webkit-animation-duration: 32233ms;
  animation-duration: 32233ms;
  -webkit-animation-delay: 33020ms;
  animation-delay: 33020ms;
}
@-webkit-keyframes move-frames-86 {
  from {
    transform: translate3d(5vw, 106vh, 0);
  }
  to {
    transform: translate3d(64vw, -107vh, 0);
  }
}
@keyframes move-frames-86 {
  from {
    transform: translate3d(5vw, 106vh, 0);
  }
  to {
    transform: translate3d(64vw, -107vh, 0);
  }
}
.circle-container:nth-child(86) .circle {
  -webkit-animation-delay: 62ms;
  animation-delay: 62ms;
}
.circle-container:nth-child(87) {
  width: 2px;
  height: 2px;
  -webkit-animation-name: move-frames-87;
  animation-name: move-frames-87;
  -webkit-animation-duration: 36408ms;
  animation-duration: 36408ms;
  -webkit-animation-delay: 24133ms;
  animation-delay: 24133ms;
}
@-webkit-keyframes move-frames-87 {
  from {
    transform: translate3d(51vw, 110vh, 0);
  }
  to {
    transform: translate3d(45vw, -125vh, 0);
  }
}
@keyframes move-frames-87 {
  from {
    transform: translate3d(51vw, 110vh, 0);
  }
  to {
    transform: translate3d(45vw, -125vh, 0);
  }
}
.circle-container:nth-child(87) .circle {
  -webkit-animation-delay: 3246ms;
  animation-delay: 3246ms;
}
.circle-container:nth-child(88) {
  width: 2px;
  height: 2px;
  -webkit-animation-name: move-frames-88;
  animation-name: move-frames-88;
  -webkit-animation-duration: 34739ms;
  animation-duration: 34739ms;
  -webkit-animation-delay: 31251ms;
  animation-delay: 31251ms;
}
@-webkit-keyframes move-frames-88 {
  from {
    transform: translate3d(79vw, 107vh, 0);
  }
  to {
    transform: translate3d(53vw, -122vh, 0);
  }
}
@keyframes move-frames-88 {
  from {
    transform: translate3d(79vw, 107vh, 0);
  }
  to {
    transform: translate3d(53vw, -122vh, 0);
  }
}
.circle-container:nth-child(88) .circle {
  -webkit-animation-delay: 3502ms;
  animation-delay: 3502ms;
}
.circle-container:nth-child(89) {
  width: 8px;
  height: 8px;
  -webkit-animation-name: move-frames-89;
  animation-name: move-frames-89;
  -webkit-animation-duration: 32159ms;
  animation-duration: 32159ms;
  -webkit-animation-delay: 26427ms;
  animation-delay: 26427ms;
}
@-webkit-keyframes move-frames-89 {
  from {
    transform: translate3d(39vw, 106vh, 0);
  }
  to {
    transform: translate3d(83vw, -125vh, 0);
  }
}
@keyframes move-frames-89 {
  from {
    transform: translate3d(39vw, 106vh, 0);
  }
  to {
    transform: translate3d(83vw, -125vh, 0);
  }
}
.circle-container:nth-child(89) .circle {
  -webkit-animation-delay: 944ms;
  animation-delay: 944ms;
}
.circle-container:nth-child(90) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-90;
  animation-name: move-frames-90;
  -webkit-animation-duration: 36592ms;
  animation-duration: 36592ms;
  -webkit-animation-delay: 25655ms;
  animation-delay: 25655ms;
}
@-webkit-keyframes move-frames-90 {
  from {
    transform: translate3d(39vw, 106vh, 0);
  }
  to {
    transform: translate3d(37vw, -116vh, 0);
  }
}
@keyframes move-frames-90 {
  from {
    transform: translate3d(39vw, 106vh, 0);
  }
  to {
    transform: translate3d(37vw, -116vh, 0);
  }
}
.circle-container:nth-child(90) .circle {
  -webkit-animation-delay: 3643ms;
  animation-delay: 3643ms;
}
.circle-container:nth-child(91) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-91;
  animation-name: move-frames-91;
  -webkit-animation-duration: 34198ms;
  animation-duration: 34198ms;
  -webkit-animation-delay: 23447ms;
  animation-delay: 23447ms;
}
@-webkit-keyframes move-frames-91 {
  from {
    transform: translate3d(87vw, 106vh, 0);
  }
  to {
    transform: translate3d(76vw, -119vh, 0);
  }
}
@keyframes move-frames-91 {
  from {
    transform: translate3d(87vw, 106vh, 0);
  }
  to {
    transform: translate3d(76vw, -119vh, 0);
  }
}
.circle-container:nth-child(91) .circle {
  -webkit-animation-delay: 500ms;
  animation-delay: 500ms;
}
.circle-container:nth-child(92) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-92;
  animation-name: move-frames-92;
  -webkit-animation-duration: 34357ms;
  animation-duration: 34357ms;
  -webkit-animation-delay: 28248ms;
  animation-delay: 28248ms;
}
@-webkit-keyframes move-frames-92 {
  from {
    transform: translate3d(93vw, 109vh, 0);
  }
  to {
    transform: translate3d(70vw, -128vh, 0);
  }
}
@keyframes move-frames-92 {
  from {
    transform: translate3d(93vw, 109vh, 0);
  }
  to {
    transform: translate3d(70vw, -128vh, 0);
  }
}
.circle-container:nth-child(92) .circle {
  -webkit-animation-delay: 1701ms;
  animation-delay: 1701ms;
}
.circle-container:nth-child(93) {
  width: 1px;
  height: 1px;
  -webkit-animation-name: move-frames-93;
  animation-name: move-frames-93;
  -webkit-animation-duration: 35664ms;
  animation-duration: 35664ms;
  -webkit-animation-delay: 5697ms;
  animation-delay: 5697ms;
}
@-webkit-keyframes move-frames-93 {
  from {
    transform: translate3d(91vw, 110vh, 0);
  }
  to {
    transform: translate3d(45vw, -140vh, 0);
  }
}
@keyframes move-frames-93 {
  from {
    transform: translate3d(91vw, 110vh, 0);
  }
  to {
    transform: translate3d(45vw, -140vh, 0);
  }
}
.circle-container:nth-child(93) .circle {
  -webkit-animation-delay: 1060ms;
  animation-delay: 1060ms;
}
.circle-container:nth-child(94) {
  width: 2px;
  height: 2px;
  -webkit-animation-name: move-frames-94;
  animation-name: move-frames-94;
  -webkit-animation-duration: 36154ms;
  animation-duration: 36154ms;
  -webkit-animation-delay: 6904ms;
  animation-delay: 6904ms;
}
@-webkit-keyframes move-frames-94 {
  from {
    transform: translate3d(64vw, 104vh, 0);
  }
  to {
    transform: translate3d(78vw, -109vh, 0);
  }
}
@keyframes move-frames-94 {
  from {
    transform: translate3d(64vw, 104vh, 0);
  }
  to {
    transform: translate3d(78vw, -109vh, 0);
  }
}
.circle-container:nth-child(94) .circle {
  -webkit-animation-delay: 3491ms;
  animation-delay: 3491ms;
}
.circle-container:nth-child(95) {
  width: 1px;
  height: 1px;
  -webkit-animation-name: move-frames-95;
  animation-name: move-frames-95;
  -webkit-animation-duration: 33587ms;
  animation-duration: 33587ms;
  -webkit-animation-delay: 31462ms;
  animation-delay: 31462ms;
}
@-webkit-keyframes move-frames-95 {
  from {
    transform: translate3d(90vw, 110vh, 0);
  }
  to {
    transform: translate3d(68vw, -120vh, 0);
  }
}
@keyframes move-frames-95 {
  from {
    transform: translate3d(90vw, 110vh, 0);
  }
  to {
    transform: translate3d(68vw, -120vh, 0);
  }
}
.circle-container:nth-child(95) .circle {
  -webkit-animation-delay: 2262ms;
  animation-delay: 2262ms;
}
.circle-container:nth-child(96) {
  width: 5px;
  height: 5px;
  -webkit-animation-name: move-frames-96;
  animation-name: move-frames-96;
  -webkit-animation-duration: 33016ms;
  animation-duration: 33016ms;
  -webkit-animation-delay: 20545ms;
  animation-delay: 20545ms;
}
@-webkit-keyframes move-frames-96 {
  from {
    transform: translate3d(47vw, 102vh, 0);
  }
  to {
    transform: translate3d(28vw, -110vh, 0);
  }
}
@keyframes move-frames-96 {
  from {
    transform: translate3d(47vw, 102vh, 0);
  }
  to {
    transform: translate3d(28vw, -110vh, 0);
  }
}
.circle-container:nth-child(96) .circle {
  -webkit-animation-delay: 1037ms;
  animation-delay: 1037ms;
}
.circle-container:nth-child(97) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-97;
  animation-name: move-frames-97;
  -webkit-animation-duration: 29976ms;
  animation-duration: 29976ms;
  -webkit-animation-delay: 17919ms;
  animation-delay: 17919ms;
}
@-webkit-keyframes move-frames-97 {
  from {
    transform: translate3d(44vw, 109vh, 0);
  }
  to {
    transform: translate3d(23vw, -119vh, 0);
  }
}
@keyframes move-frames-97 {
  from {
    transform: translate3d(44vw, 109vh, 0);
  }
  to {
    transform: translate3d(23vw, -119vh, 0);
  }
}
.circle-container:nth-child(97) .circle {
  -webkit-animation-delay: 3170ms;
  animation-delay: 3170ms;
}
.circle-container:nth-child(98) {
  width: 1px;
  height: 1px;
  -webkit-animation-name: move-frames-98;
  animation-name: move-frames-98;
  -webkit-animation-duration: 36176ms;
  animation-duration: 36176ms;
  -webkit-animation-delay: 22450ms;
  animation-delay: 22450ms;
}
@-webkit-keyframes move-frames-98 {
  from {
    transform: translate3d(92vw, 102vh, 0);
  }
  to {
    transform: translate3d(74vw, -132vh, 0);
  }
}
@keyframes move-frames-98 {
  from {
    transform: translate3d(92vw, 102vh, 0);
  }
  to {
    transform: translate3d(74vw, -132vh, 0);
  }
}
.circle-container:nth-child(98) .circle {
  -webkit-animation-delay: 2195ms;
  animation-delay: 2195ms;
}
.circle-container:nth-child(99) {
  width: 2px;
  height: 2px;
  -webkit-animation-name: move-frames-99;
  animation-name: move-frames-99;
  -webkit-animation-duration: 31872ms;
  animation-duration: 31872ms;
  -webkit-animation-delay: 19502ms;
  animation-delay: 19502ms;
}
@-webkit-keyframes move-frames-99 {
  from {
    transform: translate3d(18vw, 110vh, 0);
  }
  to {
    transform: translate3d(42vw, -112vh, 0);
  }
}
@keyframes move-frames-99 {
  from {
    transform: translate3d(18vw, 110vh, 0);
  }
  to {
    transform: translate3d(42vw, -112vh, 0);
  }
}
.circle-container:nth-child(99) .circle {
  -webkit-animation-delay: 2690ms;
  animation-delay: 2690ms;
}
.circle-container:nth-child(100) {
  width: 6px;
  height: 6px;
  -webkit-animation-name: move-frames-100;
  animation-name: move-frames-100;
  -webkit-animation-duration: 30753ms;
  animation-duration: 30753ms;
  -webkit-animation-delay: 26792ms;
  animation-delay: 26792ms;
}
@-webkit-keyframes move-frames-100 {
  from {
    transform: translate3d(60vw, 109vh, 0);
  }
  to {
    transform: translate3d(59vw, -121vh, 0);
  }
}
@keyframes move-frames-100 {
  from {
    transform: translate3d(60vw, 109vh, 0);
  }
  to {
    transform: translate3d(59vw, -121vh, 0);
  }
}
.circle-container:nth-child(100) .circle {
  -webkit-animation-delay: 436ms;
  animation-delay: 436ms;
}
.circle-container:nth-child(101) {
  width: 2px;
  height: 2px;
  -webkit-animation-name: move-frames-101;
  animation-name: move-frames-101;
  -webkit-animation-duration: 31909ms;
  animation-duration: 31909ms;
  -webkit-animation-delay: 18941ms;
  animation-delay: 18941ms;
}
@-webkit-keyframes move-frames-101 {
  from {
    transform: translate3d(6vw, 105vh, 0);
  }
  to {
    transform: translate3d(10vw, -123vh, 0);
  }
}
@keyframes move-frames-101 {
  from {
    transform: translate3d(6vw, 105vh, 0);
  }
  to {
    transform: translate3d(10vw, -123vh, 0);
  }
}
.circle-container:nth-child(101) .circle {
  -webkit-animation-delay: 3906ms;
  animation-delay: 3906ms;
}
.circle-container:nth-child(102) {
  width: 1px;
  height: 1px;
  -webkit-animation-name: move-frames-102;
  animation-name: move-frames-102;
  -webkit-animation-duration: 28405ms;
  animation-duration: 28405ms;
  -webkit-animation-delay: 5909ms;
  animation-delay: 5909ms;
}
@-webkit-keyframes move-frames-102 {
  from {
    transform: translate3d(45vw, 102vh, 0);
  }
  to {
    transform: translate3d(81vw, -106vh, 0);
  }
}
@keyframes move-frames-102 {
  from {
    transform: translate3d(45vw, 102vh, 0);
  }
  to {
    transform: translate3d(81vw, -106vh, 0);
  }
}
.circle-container:nth-child(102) .circle {
  -webkit-animation-delay: 1271ms;
  animation-delay: 1271ms;
}
.circle-container:nth-child(103) {
  width: 7px;
  height: 7px;
  -webkit-animation-name: move-frames-103;
  animation-name: move-frames-103;
  -webkit-animation-duration: 32351ms;
  animation-duration: 32351ms;
  -webkit-animation-delay: 28607ms;
  animation-delay: 28607ms;
}
@-webkit-keyframes move-frames-103 {
  from {
    transform: translate3d(98vw, 106vh, 0);
  }
  to {
    transform: translate3d(95vw, -129vh, 0);
  }
}
@keyframes move-frames-103 {
  from {
    transform: translate3d(98vw, 106vh, 0);
  }
  to {
    transform: translate3d(95vw, -129vh, 0);
  }
}
.circle-container:nth-child(103) .circle {
  -webkit-animation-delay: 3937ms;
  animation-delay: 3937ms;
}
.circle-container:nth-child(104) {
  width: 8px;
  height: 8px;
  -webkit-animation-name: move-frames-104;
  animation-name: move-frames-104;
  -webkit-animation-duration: 36138ms;
  animation-duration: 36138ms;
  -webkit-animation-delay: 32656ms;
  animation-delay: 32656ms;
}
@-webkit-keyframes move-frames-104 {
  from {
    transform: translate3d(2vw, 110vh, 0);
  }
  to {
    transform: translate3d(35vw, -137vh, 0);
  }
}
@keyframes move-frames-104 {
  from {
    transform: translate3d(2vw, 110vh, 0);
  }
  to {
    transform: translate3d(35vw, -137vh, 0);
  }
}
.circle-container:nth-child(104) .circle {
  -webkit-animation-delay: 2161ms;
  animation-delay: 2161ms;
}
.circle-container:nth-child(105) {
  width: 5px;
  height: 5px;
  -webkit-animation-name: move-frames-105;
  animation-name: move-frames-105;
  -webkit-animation-duration: 32573ms;
  animation-duration: 32573ms;
  -webkit-animation-delay: 12029ms;
  animation-delay: 12029ms;
}
@-webkit-keyframes move-frames-105 {
  from {
    transform: translate3d(100vw, 107vh, 0);
  }
  to {
    transform: translate3d(59vw, -117vh, 0);
  }
}
@keyframes move-frames-105 {
  from {
    transform: translate3d(100vw, 107vh, 0);
  }
  to {
    transform: translate3d(59vw, -117vh, 0);
  }
}
.circle-container:nth-child(105) .circle {
  -webkit-animation-delay: 2216ms;
  animation-delay: 2216ms;
}
.circle-container:nth-child(106) {
  width: 6px;
  height: 6px;
  -webkit-animation-name: move-frames-106;
  animation-name: move-frames-106;
  -webkit-animation-duration: 33856ms;
  animation-duration: 33856ms;
  -webkit-animation-delay: 12553ms;
  animation-delay: 12553ms;
}
@-webkit-keyframes move-frames-106 {
  from {
    transform: translate3d(2vw, 101vh, 0);
  }
  to {
    transform: translate3d(15vw, -121vh, 0);
  }
}
@keyframes move-frames-106 {
  from {
    transform: translate3d(2vw, 101vh, 0);
  }
  to {
    transform: translate3d(15vw, -121vh, 0);
  }
}
.circle-container:nth-child(106) .circle {
  -webkit-animation-delay: 2635ms;
  animation-delay: 2635ms;
}
.circle-container:nth-child(107) {
  width: 6px;
  height: 6px;
  -webkit-animation-name: move-frames-107;
  animation-name: move-frames-107;
  -webkit-animation-duration: 33343ms;
  animation-duration: 33343ms;
  -webkit-animation-delay: 16941ms;
  animation-delay: 16941ms;
}
@-webkit-keyframes move-frames-107 {
  from {
    transform: translate3d(68vw, 110vh, 0);
  }
  to {
    transform: translate3d(19vw, -124vh, 0);
  }
}
@keyframes move-frames-107 {
  from {
    transform: translate3d(68vw, 110vh, 0);
  }
  to {
    transform: translate3d(19vw, -124vh, 0);
  }
}
.circle-container:nth-child(107) .circle {
  -webkit-animation-delay: 2218ms;
  animation-delay: 2218ms;
}
.circle-container:nth-child(108) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-108;
  animation-name: move-frames-108;
  -webkit-animation-duration: 35431ms;
  animation-duration: 35431ms;
  -webkit-animation-delay: 13957ms;
  animation-delay: 13957ms;
}
@-webkit-keyframes move-frames-108 {
  from {
    transform: translate3d(82vw, 102vh, 0);
  }
  to {
    transform: translate3d(18vw, -109vh, 0);
  }
}
@keyframes move-frames-108 {
  from {
    transform: translate3d(82vw, 102vh, 0);
  }
  to {
    transform: translate3d(18vw, -109vh, 0);
  }
}
.circle-container:nth-child(108) .circle {
  -webkit-animation-delay: 2054ms;
  animation-delay: 2054ms;
}
.circle-container:nth-child(109) {
  width: 4px;
  height: 4px;
  -webkit-animation-name: move-frames-109;
  animation-name: move-frames-109;
  -webkit-animation-duration: 31015ms;
  animation-duration: 31015ms;
  -webkit-animation-delay: 26268ms;
  animation-delay: 26268ms;
}
@-webkit-keyframes move-frames-109 {
  from {
    transform: translate3d(68vw, 102vh, 0);
  }
  to {
    transform: translate3d(20vw, -109vh, 0);
  }
}
@keyframes move-frames-109 {
  from {
    transform: translate3d(68vw, 102vh, 0);
  }
  to {
    transform: translate3d(20vw, -109vh, 0);
  }
}
.circle-container:nth-child(109) .circle {
  -webkit-animation-delay: 200ms;
  animation-delay: 200ms;
}
.circle-container:nth-child(110) {
  width: 5px;
  height: 5px;
  -webkit-animation-name: move-frames-110;
  animation-name: move-frames-110;
  -webkit-animation-duration: 28710ms;
  animation-duration: 28710ms;
  -webkit-animation-delay: 34410ms;
  animation-delay: 34410ms;
}
@-webkit-keyframes move-frames-110 {
  from {
    transform: translate3d(77vw, 101vh, 0);
  }
  to {
    transform: translate3d(77vw, -104vh, 0);
  }
}
@keyframes move-frames-110 {
  from {
    transform: translate3d(77vw, 101vh, 0);
  }
  to {
    transform: translate3d(77vw, -104vh, 0);
  }
}
.circle-container:nth-child(110) .circle {
  -webkit-animation-delay: 1632ms;
  animation-delay: 1632ms;
}
.circle-container:nth-child(111) {
  width: 1px;
  height: 1px;
  -webkit-animation-name: move-frames-111;
  animation-name: move-frames-111;
  -webkit-animation-duration: 30688ms;
  animation-duration: 30688ms;
  -webkit-animation-delay: 15639ms;
  animation-delay: 15639ms;
}
@-webkit-keyframes move-frames-111 {
  from {
    transform: translate3d(2vw, 103vh, 0);
  }
  to {
    transform: translate3d(20vw, -105vh, 0);
  }
}
@keyframes move-frames-111 {
  from {
    transform: translate3d(2vw, 103vh, 0);
  }
  to {
    transform: translate3d(20vw, -105vh, 0);
  }
}
.circle-container:nth-child(111) .circle {
  -webkit-animation-delay: 3528ms;
  animation-delay: 3528ms;
}
.circle-container:nth-child(112) {
  width: 5px;
  height: 5px;
  -webkit-animation-name: move-frames-112;
  animation-name: move-frames-112;
  -webkit-animation-duration: 30748ms;
  animation-duration: 30748ms;
  -webkit-animation-delay: 2005ms;
  animation-delay: 2005ms;
}
@-webkit-keyframes move-frames-112 {
  from {
    transform: translate3d(91vw, 101vh, 0);
  }
  to {
    transform: translate3d(68vw, -126vh, 0);
  }
}
@keyframes move-frames-112 {
  from {
    transform: translate3d(91vw, 101vh, 0);
  }
  to {
    transform: translate3d(68vw, -126vh, 0);
  }
}
.circle-container:nth-child(112) .circle {
  -webkit-animation-delay: 1916ms;
  animation-delay: 1916ms;
}
.circle-container:nth-child(113) {
  width: 8px;
  height: 8px;
  -webkit-animation-name: move-frames-113;
  animation-name: move-frames-113;
  -webkit-animation-duration: 28248ms;
  animation-duration: 28248ms;
  -webkit-animation-delay: 21892ms;
  animation-delay: 21892ms;
}
@-webkit-keyframes move-frames-113 {
  from {
    transform: translate3d(45vw, 105vh, 0);
  }
  to {
    transform: translate3d(8vw, -135vh, 0);
  }
}
@keyframes move-frames-113 {
  from {
    transform: translate3d(45vw, 105vh, 0);
  }
  to {
    transform: translate3d(8vw, -135vh, 0);
  }
}
.circle-container:nth-child(113) .circle {
  -webkit-animation-delay: 2578ms;
  animation-delay: 2578ms;
}
.circle-container:nth-child(114) {
  width: 8px;
  height: 8px;
  -webkit-animation-name: move-frames-114;
  animation-name: move-frames-114;
  -webkit-animation-duration: 36486ms;
  animation-duration: 36486ms;
  -webkit-animation-delay: 13941ms;
  animation-delay: 13941ms;
}
@-webkit-keyframes move-frames-114 {
  from {
    transform: translate3d(59vw, 104vh, 0);
  }
  to {
    transform: translate3d(5vw, -108vh, 0);
  }
}
@keyframes move-frames-114 {
  from {
    transform: translate3d(59vw, 104vh, 0);
  }
  to {
    transform: translate3d(5vw, -108vh, 0);
  }
}
.circle-container:nth-child(114) .circle {
  -webkit-animation-delay: 114ms;
  animation-delay: 114ms;
}
.circle-container:nth-child(115) {
  width: 6px;
  height: 6px;
  -webkit-animation-name: move-frames-115;
  animation-name: move-frames-115;
  -webkit-animation-duration: 34882ms;
  animation-duration: 34882ms;
  -webkit-animation-delay: 16558ms;
  animation-delay: 16558ms;
}
@-webkit-keyframes move-frames-115 {
  from {
    transform: translate3d(5vw, 107vh, 0);
  }
  to {
    transform: translate3d(19vw, -119vh, 0);
  }
}
@keyframes move-frames-115 {
  from {
    transform: translate3d(5vw, 107vh, 0);
  }
  to {
    transform: translate3d(19vw, -119vh, 0);
  }
}
.circle-container:nth-child(115) .circle {
  -webkit-animation-delay: 2225ms;
  animation-delay: 2225ms;
}
.circle-container:nth-child(116) {
  width: 7px;
  height: 7px;
  -webkit-animation-name: move-frames-116;
  animation-name: move-frames-116;
  -webkit-animation-duration: 28373ms;
  animation-duration: 28373ms;
  -webkit-animation-delay: 36637ms;
  animation-delay: 36637ms;
}
@-webkit-keyframes move-frames-116 {
  from {
    transform: translate3d(56vw, 109vh, 0);
  }
  to {
    transform: translate3d(100vw, -118vh, 0);
  }
}
@keyframes move-frames-116 {
  from {
    transform: translate3d(56vw, 109vh, 0);
  }
  to {
    transform: translate3d(100vw, -118vh, 0);
  }
}
.circle-container:nth-child(116) .circle {
  -webkit-animation-delay: 1503ms;
  animation-delay: 1503ms;
}
.circle-container:nth-child(117) {
  width: 2px;
  height: 2px;
  -webkit-animation-name: move-frames-117;
  animation-name: move-frames-117;
  -webkit-animation-duration: 36345ms;
  animation-duration: 36345ms;
  -webkit-animation-delay: 3563ms;
  animation-delay: 3563ms;
}
@-webkit-keyframes move-frames-117 {
  from {
    transform: translate3d(65vw, 105vh, 0);
  }
  to {
    transform: translate3d(29vw, -133vh, 0);
  }
}
@keyframes move-frames-117 {
  from {
    transform: translate3d(65vw, 105vh, 0);
  }
  to {
    transform: translate3d(29vw, -133vh, 0);
  }
}
.circle-container:nth-child(117) .circle {
  -webkit-animation-delay: 1469ms;
  animation-delay: 1469ms;
}
.circle-container:nth-child(118) {
  width: 4px;
  height: 4px;
  -webkit-animation-name: move-frames-118;
  animation-name: move-frames-118;
  -webkit-animation-duration: 30753ms;
  animation-duration: 30753ms;
  -webkit-animation-delay: 34883ms;
  animation-delay: 34883ms;
}
@-webkit-keyframes move-frames-118 {
  from {
    transform: translate3d(46vw, 104vh, 0);
  }
  to {
    transform: translate3d(86vw, -114vh, 0);
  }
}
@keyframes move-frames-118 {
  from {
    transform: translate3d(46vw, 104vh, 0);
  }
  to {
    transform: translate3d(86vw, -114vh, 0);
  }
}
.circle-container:nth-child(118) .circle {
  -webkit-animation-delay: 2601ms;
  animation-delay: 2601ms;
}
.circle-container:nth-child(119) {
  width: 4px;
  height: 4px;
  -webkit-animation-name: move-frames-119;
  animation-name: move-frames-119;
  -webkit-animation-duration: 30445ms;
  animation-duration: 30445ms;
  -webkit-animation-delay: 9443ms;
  animation-delay: 9443ms;
}
@-webkit-keyframes move-frames-119 {
  from {
    transform: translate3d(68vw, 108vh, 0);
  }
  to {
    transform: translate3d(21vw, -128vh, 0);
  }
}
@keyframes move-frames-119 {
  from {
    transform: translate3d(68vw, 108vh, 0);
  }
  to {
    transform: translate3d(21vw, -128vh, 0);
  }
}
.circle-container:nth-child(119) .circle {
  -webkit-animation-delay: 1373ms;
  animation-delay: 1373ms;
}
.circle-container:nth-child(120) {
  width: 1px;
  height: 1px;
  -webkit-animation-name: move-frames-120;
  animation-name: move-frames-120;
  -webkit-animation-duration: 32042ms;
  animation-duration: 32042ms;
  -webkit-animation-delay: 29955ms;
  animation-delay: 29955ms;
}
@-webkit-keyframes move-frames-120 {
  from {
    transform: translate3d(48vw, 101vh, 0);
  }
  to {
    transform: translate3d(94vw, -126vh, 0);
  }
}
@keyframes move-frames-120 {
  from {
    transform: translate3d(48vw, 101vh, 0);
  }
  to {
    transform: translate3d(94vw, -126vh, 0);
  }
}
.circle-container:nth-child(120) .circle {
  -webkit-animation-delay: 3445ms;
  animation-delay: 3445ms;
}
.circle-container:nth-child(121) {
  width: 4px;
  height: 4px;
  -webkit-animation-name: move-frames-121;
  animation-name: move-frames-121;
  -webkit-animation-duration: 32654ms;
  animation-duration: 32654ms;
  -webkit-animation-delay: 26894ms;
  animation-delay: 26894ms;
}
@-webkit-keyframes move-frames-121 {
  from {
    transform: translate3d(92vw, 104vh, 0);
  }
  to {
    transform: translate3d(69vw, -117vh, 0);
  }
}
@keyframes move-frames-121 {
  from {
    transform: translate3d(92vw, 104vh, 0);
  }
  to {
    transform: translate3d(69vw, -117vh, 0);
  }
}
.circle-container:nth-child(121) .circle {
  -webkit-animation-delay: 2391ms;
  animation-delay: 2391ms;
}
.circle-container:nth-child(122) {
  width: 1px;
  height: 1px;
  -webkit-animation-name: move-frames-122;
  animation-name: move-frames-122;
  -webkit-animation-duration: 30909ms;
  animation-duration: 30909ms;
  -webkit-animation-delay: 29973ms;
  animation-delay: 29973ms;
}
@-webkit-keyframes move-frames-122 {
  from {
    transform: translate3d(82vw, 103vh, 0);
  }
  to {
    transform: translate3d(73vw, -104vh, 0);
  }
}
@keyframes move-frames-122 {
  from {
    transform: translate3d(82vw, 103vh, 0);
  }
  to {
    transform: translate3d(73vw, -104vh, 0);
  }
}
.circle-container:nth-child(122) .circle {
  -webkit-animation-delay: 2233ms;
  animation-delay: 2233ms;
}
.circle-container:nth-child(123) {
  width: 6px;
  height: 6px;
  -webkit-animation-name: move-frames-123;
  animation-name: move-frames-123;
  -webkit-animation-duration: 33462ms;
  animation-duration: 33462ms;
  -webkit-animation-delay: 4158ms;
  animation-delay: 4158ms;
}
@-webkit-keyframes move-frames-123 {
  from {
    transform: translate3d(5vw, 106vh, 0);
  }
  to {
    transform: translate3d(74vw, -136vh, 0);
  }
}
@keyframes move-frames-123 {
  from {
    transform: translate3d(5vw, 106vh, 0);
  }
  to {
    transform: translate3d(74vw, -136vh, 0);
  }
}
.circle-container:nth-child(123) .circle {
  -webkit-animation-delay: 1827ms;
  animation-delay: 1827ms;
}
.circle-container:nth-child(124) {
  width: 2px;
  height: 2px;
  -webkit-animation-name: move-frames-124;
  animation-name: move-frames-124;
  -webkit-animation-duration: 34250ms;
  animation-duration: 34250ms;
  -webkit-animation-delay: 8411ms;
  animation-delay: 8411ms;
}
@-webkit-keyframes move-frames-124 {
  from {
    transform: translate3d(18vw, 106vh, 0);
  }
  to {
    transform: translate3d(73vw, -133vh, 0);
  }
}
@keyframes move-frames-124 {
  from {
    transform: translate3d(18vw, 106vh, 0);
  }
  to {
    transform: translate3d(73vw, -133vh, 0);
  }
}
.circle-container:nth-child(124) .circle {
  -webkit-animation-delay: 1525ms;
  animation-delay: 1525ms;
}
.circle-container:nth-child(125) {
  width: 5px;
  height: 5px;
  -webkit-animation-name: move-frames-125;
  animation-name: move-frames-125;
  -webkit-animation-duration: 35411ms;
  animation-duration: 35411ms;
  -webkit-animation-delay: 34872ms;
  animation-delay: 34872ms;
}
@-webkit-keyframes move-frames-125 {
  from {
    transform: translate3d(72vw, 107vh, 0);
  }
  to {
    transform: translate3d(47vw, -123vh, 0);
  }
}
@keyframes move-frames-125 {
  from {
    transform: translate3d(72vw, 107vh, 0);
  }
  to {
    transform: translate3d(47vw, -123vh, 0);
  }
}
.circle-container:nth-child(125) .circle {
  -webkit-animation-delay: 3189ms;
  animation-delay: 3189ms;
}
.circle-container:nth-child(126) {
  width: 2px;
  height: 2px;
  -webkit-animation-name: move-frames-126;
  animation-name: move-frames-126;
  -webkit-animation-duration: 30149ms;
  animation-duration: 30149ms;
  -webkit-animation-delay: 981ms;
  animation-delay: 981ms;
}
@-webkit-keyframes move-frames-126 {
  from {
    transform: translate3d(3vw, 103vh, 0);
  }
  to {
    transform: translate3d(100vw, -128vh, 0);
  }
}
@keyframes move-frames-126 {
  from {
    transform: translate3d(3vw, 103vh, 0);
  }
  to {
    transform: translate3d(100vw, -128vh, 0);
  }
}
.circle-container:nth-child(126) .circle {
  -webkit-animation-delay: 701ms;
  animation-delay: 701ms;
}
.circle-container:nth-child(127) {
  width: 8px;
  height: 8px;
  -webkit-animation-name: move-frames-127;
  animation-name: move-frames-127;
  -webkit-animation-duration: 35277ms;
  animation-duration: 35277ms;
  -webkit-animation-delay: 9538ms;
  animation-delay: 9538ms;
}
@-webkit-keyframes move-frames-127 {
  from {
    transform: translate3d(40vw, 103vh, 0);
  }
  to {
    transform: translate3d(13vw, -115vh, 0);
  }
}
@keyframes move-frames-127 {
  from {
    transform: translate3d(40vw, 103vh, 0);
  }
  to {
    transform: translate3d(13vw, -115vh, 0);
  }
}
.circle-container:nth-child(127) .circle {
  -webkit-animation-delay: 2340ms;
  animation-delay: 2340ms;
}
.circle-container:nth-child(128) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-128;
  animation-name: move-frames-128;
  -webkit-animation-duration: 32904ms;
  animation-duration: 32904ms;
  -webkit-animation-delay: 11328ms;
  animation-delay: 11328ms;
}
@-webkit-keyframes move-frames-128 {
  from {
    transform: translate3d(77vw, 103vh, 0);
  }
  to {
    transform: translate3d(14vw, -132vh, 0);
  }
}
@keyframes move-frames-128 {
  from {
    transform: translate3d(77vw, 103vh, 0);
  }
  to {
    transform: translate3d(14vw, -132vh, 0);
  }
}
.circle-container:nth-child(128) .circle {
  -webkit-animation-delay: 796ms;
  animation-delay: 796ms;
}
.circle-container:nth-child(129) {
  width: 5px;
  height: 5px;
  -webkit-animation-name: move-frames-129;
  animation-name: move-frames-129;
  -webkit-animation-duration: 29450ms;
  animation-duration: 29450ms;
  -webkit-animation-delay: 23593ms;
  animation-delay: 23593ms;
}
@-webkit-keyframes move-frames-129 {
  from {
    transform: translate3d(17vw, 105vh, 0);
  }
  to {
    transform: translate3d(83vw, -113vh, 0);
  }
}
@keyframes move-frames-129 {
  from {
    transform: translate3d(17vw, 105vh, 0);
  }
  to {
    transform: translate3d(83vw, -113vh, 0);
  }
}
.circle-container:nth-child(129) .circle {
  -webkit-animation-delay: 1641ms;
  animation-delay: 1641ms;
}
.circle-container:nth-child(130) {
  width: 6px;
  height: 6px;
  -webkit-animation-name: move-frames-130;
  animation-name: move-frames-130;
  -webkit-animation-duration: 36824ms;
  animation-duration: 36824ms;
  -webkit-animation-delay: 11302ms;
  animation-delay: 11302ms;
}
@-webkit-keyframes move-frames-130 {
  from {
    transform: translate3d(54vw, 110vh, 0);
  }
  to {
    transform: translate3d(13vw, -132vh, 0);
  }
}
@keyframes move-frames-130 {
  from {
    transform: translate3d(54vw, 110vh, 0);
  }
  to {
    transform: translate3d(13vw, -132vh, 0);
  }
}
.circle-container:nth-child(130) .circle {
  -webkit-animation-delay: 693ms;
  animation-delay: 693ms;
}
.circle-container:nth-child(131) {
  width: 4px;
  height: 4px;
  -webkit-animation-name: move-frames-131;
  animation-name: move-frames-131;
  -webkit-animation-duration: 35540ms;
  animation-duration: 35540ms;
  -webkit-animation-delay: 13626ms;
  animation-delay: 13626ms;
}
@-webkit-keyframes move-frames-131 {
  from {
    transform: translate3d(18vw, 103vh, 0);
  }
  to {
    transform: translate3d(27vw, -125vh, 0);
  }
}
@keyframes move-frames-131 {
  from {
    transform: translate3d(18vw, 103vh, 0);
  }
  to {
    transform: translate3d(27vw, -125vh, 0);
  }
}
.circle-container:nth-child(131) .circle {
  -webkit-animation-delay: 2765ms;
  animation-delay: 2765ms;
}
.circle-container:nth-child(132) {
  width: 2px;
  height: 2px;
  -webkit-animation-name: move-frames-132;
  animation-name: move-frames-132;
  -webkit-animation-duration: 34550ms;
  animation-duration: 34550ms;
  -webkit-animation-delay: 12049ms;
  animation-delay: 12049ms;
}
@-webkit-keyframes move-frames-132 {
  from {
    transform: translate3d(48vw, 107vh, 0);
  }
  to {
    transform: translate3d(48vw, -118vh, 0);
  }
}
@keyframes move-frames-132 {
  from {
    transform: translate3d(48vw, 107vh, 0);
  }
  to {
    transform: translate3d(48vw, -118vh, 0);
  }
}
.circle-container:nth-child(132) .circle {
  -webkit-animation-delay: 113ms;
  animation-delay: 113ms;
}
.circle-container:nth-child(133) {
  width: 8px;
  height: 8px;
  -webkit-animation-name: move-frames-133;
  animation-name: move-frames-133;
  -webkit-animation-duration: 32458ms;
  animation-duration: 32458ms;
  -webkit-animation-delay: 36080ms;
  animation-delay: 36080ms;
}
@-webkit-keyframes move-frames-133 {
  from {
    transform: translate3d(91vw, 108vh, 0);
  }
  to {
    transform: translate3d(11vw, -118vh, 0);
  }
}
@keyframes move-frames-133 {
  from {
    transform: translate3d(91vw, 108vh, 0);
  }
  to {
    transform: translate3d(11vw, -118vh, 0);
  }
}
.circle-container:nth-child(133) .circle {
  -webkit-animation-delay: 1011ms;
  animation-delay: 1011ms;
}
.circle-container:nth-child(134) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-134;
  animation-name: move-frames-134;
  -webkit-animation-duration: 33598ms;
  animation-duration: 33598ms;
  -webkit-animation-delay: 33593ms;
  animation-delay: 33593ms;
}
@-webkit-keyframes move-frames-134 {
  from {
    transform: translate3d(86vw, 109vh, 0);
  }
  to {
    transform: translate3d(84vw, -127vh, 0);
  }
}
@keyframes move-frames-134 {
  from {
    transform: translate3d(86vw, 109vh, 0);
  }
  to {
    transform: translate3d(84vw, -127vh, 0);
  }
}
.circle-container:nth-child(134) .circle {
  -webkit-animation-delay: 3642ms;
  animation-delay: 3642ms;
}
.circle-container:nth-child(135) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-135;
  animation-name: move-frames-135;
  -webkit-animation-duration: 32883ms;
  animation-duration: 32883ms;
  -webkit-animation-delay: 32604ms;
  animation-delay: 32604ms;
}
@-webkit-keyframes move-frames-135 {
  from {
    transform: translate3d(21vw, 104vh, 0);
  }
  to {
    transform: translate3d(38vw, -108vh, 0);
  }
}
@keyframes move-frames-135 {
  from {
    transform: translate3d(21vw, 104vh, 0);
  }
  to {
    transform: translate3d(38vw, -108vh, 0);
  }
}
.circle-container:nth-child(135) .circle {
  -webkit-animation-delay: 883ms;
  animation-delay: 883ms;
}
.circle-container:nth-child(136) {
  width: 6px;
  height: 6px;
  -webkit-animation-name: move-frames-136;
  animation-name: move-frames-136;
  -webkit-animation-duration: 28467ms;
  animation-duration: 28467ms;
  -webkit-animation-delay: 27241ms;
  animation-delay: 27241ms;
}
@-webkit-keyframes move-frames-136 {
  from {
    transform: translate3d(80vw, 104vh, 0);
  }
  to {
    transform: translate3d(59vw, -116vh, 0);
  }
}
@keyframes move-frames-136 {
  from {
    transform: translate3d(80vw, 104vh, 0);
  }
  to {
    transform: translate3d(59vw, -116vh, 0);
  }
}
.circle-container:nth-child(136) .circle {
  -webkit-animation-delay: 990ms;
  animation-delay: 990ms;
}
.circle-container:nth-child(137) {
  width: 8px;
  height: 8px;
  -webkit-animation-name: move-frames-137;
  animation-name: move-frames-137;
  -webkit-animation-duration: 30716ms;
  animation-duration: 30716ms;
  -webkit-animation-delay: 17698ms;
  animation-delay: 17698ms;
}
@-webkit-keyframes move-frames-137 {
  from {
    transform: translate3d(73vw, 104vh, 0);
  }
  to {
    transform: translate3d(76vw, -109vh, 0);
  }
}
@keyframes move-frames-137 {
  from {
    transform: translate3d(73vw, 104vh, 0);
  }
  to {
    transform: translate3d(76vw, -109vh, 0);
  }
}
.circle-container:nth-child(137) .circle {
  -webkit-animation-delay: 3753ms;
  animation-delay: 3753ms;
}
.circle-container:nth-child(138) {
  width: 2px;
  height: 2px;
  -webkit-animation-name: move-frames-138;
  animation-name: move-frames-138;
  -webkit-animation-duration: 31487ms;
  animation-duration: 31487ms;
  -webkit-animation-delay: 31468ms;
  animation-delay: 31468ms;
}
@-webkit-keyframes move-frames-138 {
  from {
    transform: translate3d(3vw, 101vh, 0);
  }
  to {
    transform: translate3d(81vw, -117vh, 0);
  }
}
@keyframes move-frames-138 {
  from {
    transform: translate3d(3vw, 101vh, 0);
  }
  to {
    transform: translate3d(81vw, -117vh, 0);
  }
}
.circle-container:nth-child(138) .circle {
  -webkit-animation-delay: 2684ms;
  animation-delay: 2684ms;
}
.circle-container:nth-child(139) {
  width: 4px;
  height: 4px;
  -webkit-animation-name: move-frames-139;
  animation-name: move-frames-139;
  -webkit-animation-duration: 35988ms;
  animation-duration: 35988ms;
  -webkit-animation-delay: 5163ms;
  animation-delay: 5163ms;
}
@-webkit-keyframes move-frames-139 {
  from {
    transform: translate3d(4vw, 101vh, 0);
  }
  to {
    transform: translate3d(16vw, -108vh, 0);
  }
}
@keyframes move-frames-139 {
  from {
    transform: translate3d(4vw, 101vh, 0);
  }
  to {
    transform: translate3d(16vw, -108vh, 0);
  }
}
.circle-container:nth-child(139) .circle {
  -webkit-animation-delay: 1986ms;
  animation-delay: 1986ms;
}
.circle-container:nth-child(140) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-140;
  animation-name: move-frames-140;
  -webkit-animation-duration: 35864ms;
  animation-duration: 35864ms;
  -webkit-animation-delay: 10258ms;
  animation-delay: 10258ms;
}
@-webkit-keyframes move-frames-140 {
  from {
    transform: translate3d(53vw, 103vh, 0);
  }
  to {
    transform: translate3d(11vw, -111vh, 0);
  }
}
@keyframes move-frames-140 {
  from {
    transform: translate3d(53vw, 103vh, 0);
  }
  to {
    transform: translate3d(11vw, -111vh, 0);
  }
}
.circle-container:nth-child(140) .circle {
  -webkit-animation-delay: 3593ms;
  animation-delay: 3593ms;
}
.circle-container:nth-child(141) {
  width: 6px;
  height: 6px;
  -webkit-animation-name: move-frames-141;
  animation-name: move-frames-141;
  -webkit-animation-duration: 30602ms;
  animation-duration: 30602ms;
  -webkit-animation-delay: 12056ms;
  animation-delay: 12056ms;
}
@-webkit-keyframes move-frames-141 {
  from {
    transform: translate3d(80vw, 102vh, 0);
  }
  to {
    transform: translate3d(73vw, -124vh, 0);
  }
}
@keyframes move-frames-141 {
  from {
    transform: translate3d(80vw, 102vh, 0);
  }
  to {
    transform: translate3d(73vw, -124vh, 0);
  }
}
.circle-container:nth-child(141) .circle {
  -webkit-animation-delay: 3088ms;
  animation-delay: 3088ms;
}
.circle-container:nth-child(142) {
  width: 7px;
  height: 7px;
  -webkit-animation-name: move-frames-142;
  animation-name: move-frames-142;
  -webkit-animation-duration: 30956ms;
  animation-duration: 30956ms;
  -webkit-animation-delay: 25605ms;
  animation-delay: 25605ms;
}
@-webkit-keyframes move-frames-142 {
  from {
    transform: translate3d(34vw, 103vh, 0);
  }
  to {
    transform: translate3d(85vw, -112vh, 0);
  }
}
@keyframes move-frames-142 {
  from {
    transform: translate3d(34vw, 103vh, 0);
  }
  to {
    transform: translate3d(85vw, -112vh, 0);
  }
}
.circle-container:nth-child(142) .circle {
  -webkit-animation-delay: 2741ms;
  animation-delay: 2741ms;
}
.circle-container:nth-child(143) {
  width: 8px;
  height: 8px;
  -webkit-animation-name: move-frames-143;
  animation-name: move-frames-143;
  -webkit-animation-duration: 29076ms;
  animation-duration: 29076ms;
  -webkit-animation-delay: 34987ms;
  animation-delay: 34987ms;
}
@-webkit-keyframes move-frames-143 {
  from {
    transform: translate3d(69vw, 105vh, 0);
  }
  to {
    transform: translate3d(69vw, -110vh, 0);
  }
}
@keyframes move-frames-143 {
  from {
    transform: translate3d(69vw, 105vh, 0);
  }
  to {
    transform: translate3d(69vw, -110vh, 0);
  }
}
.circle-container:nth-child(143) .circle {
  -webkit-animation-delay: 3515ms;
  animation-delay: 3515ms;
}
.circle-container:nth-child(144) {
  width: 2px;
  height: 2px;
  -webkit-animation-name: move-frames-144;
  animation-name: move-frames-144;
  -webkit-animation-duration: 32262ms;
  animation-duration: 32262ms;
  -webkit-animation-delay: 6479ms;
  animation-delay: 6479ms;
}
@-webkit-keyframes move-frames-144 {
  from {
    transform: translate3d(28vw, 109vh, 0);
  }
  to {
    transform: translate3d(34vw, -125vh, 0);
  }
}
@keyframes move-frames-144 {
  from {
    transform: translate3d(28vw, 109vh, 0);
  }
  to {
    transform: translate3d(34vw, -125vh, 0);
  }
}
.circle-container:nth-child(144) .circle {
  -webkit-animation-delay: 734ms;
  animation-delay: 734ms;
}
.circle-container:nth-child(145) {
  width: 5px;
  height: 5px;
  -webkit-animation-name: move-frames-145;
  animation-name: move-frames-145;
  -webkit-animation-duration: 34909ms;
  animation-duration: 34909ms;
  -webkit-animation-delay: 7926ms;
  animation-delay: 7926ms;
}
@-webkit-keyframes move-frames-145 {
  from {
    transform: translate3d(30vw, 105vh, 0);
  }
  to {
    transform: translate3d(55vw, -113vh, 0);
  }
}
@keyframes move-frames-145 {
  from {
    transform: translate3d(30vw, 105vh, 0);
  }
  to {
    transform: translate3d(55vw, -113vh, 0);
  }
}
.circle-container:nth-child(145) .circle {
  -webkit-animation-delay: 3847ms;
  animation-delay: 3847ms;
}
.circle-container:nth-child(146) {
  width: 7px;
  height: 7px;
  -webkit-animation-name: move-frames-146;
  animation-name: move-frames-146;
  -webkit-animation-duration: 33187ms;
  animation-duration: 33187ms;
  -webkit-animation-delay: 15186ms;
  animation-delay: 15186ms;
}
@-webkit-keyframes move-frames-146 {
  from {
    transform: translate3d(8vw, 101vh, 0);
  }
  to {
    transform: translate3d(9vw, -118vh, 0);
  }
}
@keyframes move-frames-146 {
  from {
    transform: translate3d(8vw, 101vh, 0);
  }
  to {
    transform: translate3d(9vw, -118vh, 0);
  }
}
.circle-container:nth-child(146) .circle {
  -webkit-animation-delay: 3863ms;
  animation-delay: 3863ms;
}
.circle-container:nth-child(147) {
  width: 2px;
  height: 2px;
  -webkit-animation-name: move-frames-147;
  animation-name: move-frames-147;
  -webkit-animation-duration: 34427ms;
  animation-duration: 34427ms;
  -webkit-animation-delay: 21836ms;
  animation-delay: 21836ms;
}
@-webkit-keyframes move-frames-147 {
  from {
    transform: translate3d(21vw, 109vh, 0);
  }
  to {
    transform: translate3d(26vw, -115vh, 0);
  }
}
@keyframes move-frames-147 {
  from {
    transform: translate3d(21vw, 109vh, 0);
  }
  to {
    transform: translate3d(26vw, -115vh, 0);
  }
}
.circle-container:nth-child(147) .circle {
  -webkit-animation-delay: 1793ms;
  animation-delay: 1793ms;
}
.circle-container:nth-child(148) {
  width: 1px;
  height: 1px;
  -webkit-animation-name: move-frames-148;
  animation-name: move-frames-148;
  -webkit-animation-duration: 32918ms;
  animation-duration: 32918ms;
  -webkit-animation-delay: 29009ms;
  animation-delay: 29009ms;
}
@-webkit-keyframes move-frames-148 {
  from {
    transform: translate3d(23vw, 110vh, 0);
  }
  to {
    transform: translate3d(81vw, -130vh, 0);
  }
}
@keyframes move-frames-148 {
  from {
    transform: translate3d(23vw, 110vh, 0);
  }
  to {
    transform: translate3d(81vw, -130vh, 0);
  }
}
.circle-container:nth-child(148) .circle {
  -webkit-animation-delay: 1787ms;
  animation-delay: 1787ms;
}
.circle-container:nth-child(149) {
  width: 1px;
  height: 1px;
  -webkit-animation-name: move-frames-149;
  animation-name: move-frames-149;
  -webkit-animation-duration: 36023ms;
  animation-duration: 36023ms;
  -webkit-animation-delay: 29327ms;
  animation-delay: 29327ms;
}
@-webkit-keyframes move-frames-149 {
  from {
    transform: translate3d(57vw, 109vh, 0);
  }
  to {
    transform: translate3d(35vw, -131vh, 0);
  }
}
@keyframes move-frames-149 {
  from {
    transform: translate3d(57vw, 109vh, 0);
  }
  to {
    transform: translate3d(35vw, -131vh, 0);
  }
}
.circle-container:nth-child(149) .circle {
  -webkit-animation-delay: 2813ms;
  animation-delay: 2813ms;
}
.circle-container:nth-child(150) {
  width: 5px;
  height: 5px;
  -webkit-animation-name: move-frames-150;
  animation-name: move-frames-150;
  -webkit-animation-duration: 32191ms;
  animation-duration: 32191ms;
  -webkit-animation-delay: 27694ms;
  animation-delay: 27694ms;
}
@-webkit-keyframes move-frames-150 {
  from {
    transform: translate3d(16vw, 107vh, 0);
  }
  to {
    transform: translate3d(61vw, -123vh, 0);
  }
}
@keyframes move-frames-150 {
  from {
    transform: translate3d(16vw, 107vh, 0);
  }
  to {
    transform: translate3d(61vw, -123vh, 0);
  }
}
.circle-container:nth-child(150) .circle {
  -webkit-animation-delay: 2852ms;
  animation-delay: 2852ms;
}
.circle-container:nth-child(151) {
  width: 7px;
  height: 7px;
  -webkit-animation-name: move-frames-151;
  animation-name: move-frames-151;
  -webkit-animation-duration: 32830ms;
  animation-duration: 32830ms;
  -webkit-animation-delay: 21077ms;
  animation-delay: 21077ms;
}
@-webkit-keyframes move-frames-151 {
  from {
    transform: translate3d(91vw, 103vh, 0);
  }
  to {
    transform: translate3d(6vw, -115vh, 0);
  }
}
@keyframes move-frames-151 {
  from {
    transform: translate3d(91vw, 103vh, 0);
  }
  to {
    transform: translate3d(6vw, -115vh, 0);
  }
}
.circle-container:nth-child(151) .circle {
  -webkit-animation-delay: 169ms;
  animation-delay: 169ms;
}
.circle-container:nth-child(152) {
  width: 2px;
  height: 2px;
  -webkit-animation-name: move-frames-152;
  animation-name: move-frames-152;
  -webkit-animation-duration: 33232ms;
  animation-duration: 33232ms;
  -webkit-animation-delay: 33169ms;
  animation-delay: 33169ms;
}
@-webkit-keyframes move-frames-152 {
  from {
    transform: translate3d(21vw, 105vh, 0);
  }
  to {
    transform: translate3d(82vw, -118vh, 0);
  }
}
@keyframes move-frames-152 {
  from {
    transform: translate3d(21vw, 105vh, 0);
  }
  to {
    transform: translate3d(82vw, -118vh, 0);
  }
}
.circle-container:nth-child(152) .circle {
  -webkit-animation-delay: 2775ms;
  animation-delay: 2775ms;
}
.circle-container:nth-child(153) {
  width: 7px;
  height: 7px;
  -webkit-animation-name: move-frames-153;
  animation-name: move-frames-153;
  -webkit-animation-duration: 34527ms;
  animation-duration: 34527ms;
  -webkit-animation-delay: 34671ms;
  animation-delay: 34671ms;
}
@-webkit-keyframes move-frames-153 {
  from {
    transform: translate3d(81vw, 107vh, 0);
  }
  to {
    transform: translate3d(9vw, -136vh, 0);
  }
}
@keyframes move-frames-153 {
  from {
    transform: translate3d(81vw, 107vh, 0);
  }
  to {
    transform: translate3d(9vw, -136vh, 0);
  }
}
.circle-container:nth-child(153) .circle {
  -webkit-animation-delay: 574ms;
  animation-delay: 574ms;
}
.circle-container:nth-child(154) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-154;
  animation-name: move-frames-154;
  -webkit-animation-duration: 28344ms;
  animation-duration: 28344ms;
  -webkit-animation-delay: 27604ms;
  animation-delay: 27604ms;
}
@-webkit-keyframes move-frames-154 {
  from {
    transform: translate3d(11vw, 103vh, 0);
  }
  to {
    transform: translate3d(95vw, -116vh, 0);
  }
}
@keyframes move-frames-154 {
  from {
    transform: translate3d(11vw, 103vh, 0);
  }
  to {
    transform: translate3d(95vw, -116vh, 0);
  }
}
.circle-container:nth-child(154) .circle {
  -webkit-animation-delay: 2481ms;
  animation-delay: 2481ms;
}
.circle-container:nth-child(155) {
  width: 4px;
  height: 4px;
  -webkit-animation-name: move-frames-155;
  animation-name: move-frames-155;
  -webkit-animation-duration: 28509ms;
  animation-duration: 28509ms;
  -webkit-animation-delay: 27579ms;
  animation-delay: 27579ms;
}
@-webkit-keyframes move-frames-155 {
  from {
    transform: translate3d(50vw, 108vh, 0);
  }
  to {
    transform: translate3d(27vw, -117vh, 0);
  }
}
@keyframes move-frames-155 {
  from {
    transform: translate3d(50vw, 108vh, 0);
  }
  to {
    transform: translate3d(27vw, -117vh, 0);
  }
}
.circle-container:nth-child(155) .circle {
  -webkit-animation-delay: 805ms;
  animation-delay: 805ms;
}
.circle-container:nth-child(156) {
  width: 7px;
  height: 7px;
  -webkit-animation-name: move-frames-156;
  animation-name: move-frames-156;
  -webkit-animation-duration: 34582ms;
  animation-duration: 34582ms;
  -webkit-animation-delay: 31557ms;
  animation-delay: 31557ms;
}
@-webkit-keyframes move-frames-156 {
  from {
    transform: translate3d(18vw, 105vh, 0);
  }
  to {
    transform: translate3d(2vw, -133vh, 0);
  }
}
@keyframes move-frames-156 {
  from {
    transform: translate3d(18vw, 105vh, 0);
  }
  to {
    transform: translate3d(2vw, -133vh, 0);
  }
}
.circle-container:nth-child(156) .circle {
  -webkit-animation-delay: 1679ms;
  animation-delay: 1679ms;
}
.circle-container:nth-child(157) {
  width: 7px;
  height: 7px;
  -webkit-animation-name: move-frames-157;
  animation-name: move-frames-157;
  -webkit-animation-duration: 32596ms;
  animation-duration: 32596ms;
  -webkit-animation-delay: 17703ms;
  animation-delay: 17703ms;
}
@-webkit-keyframes move-frames-157 {
  from {
    transform: translate3d(41vw, 109vh, 0);
  }
  to {
    transform: translate3d(87vw, -138vh, 0);
  }
}
@keyframes move-frames-157 {
  from {
    transform: translate3d(41vw, 109vh, 0);
  }
  to {
    transform: translate3d(87vw, -138vh, 0);
  }
}
.circle-container:nth-child(157) .circle {
  -webkit-animation-delay: 3511ms;
  animation-delay: 3511ms;
}
.circle-container:nth-child(158) {
  width: 5px;
  height: 5px;
  -webkit-animation-name: move-frames-158;
  animation-name: move-frames-158;
  -webkit-animation-duration: 30090ms;
  animation-duration: 30090ms;
  -webkit-animation-delay: 35431ms;
  animation-delay: 35431ms;
}
@-webkit-keyframes move-frames-158 {
  from {
    transform: translate3d(35vw, 103vh, 0);
  }
  to {
    transform: translate3d(96vw, -126vh, 0);
  }
}
@keyframes move-frames-158 {
  from {
    transform: translate3d(35vw, 103vh, 0);
  }
  to {
    transform: translate3d(96vw, -126vh, 0);
  }
}
.circle-container:nth-child(158) .circle {
  -webkit-animation-delay: 2392ms;
  animation-delay: 2392ms;
}
.circle-container:nth-child(159) {
  width: 4px;
  height: 4px;
  -webkit-animation-name: move-frames-159;
  animation-name: move-frames-159;
  -webkit-animation-duration: 33707ms;
  animation-duration: 33707ms;
  -webkit-animation-delay: 8298ms;
  animation-delay: 8298ms;
}
@-webkit-keyframes move-frames-159 {
  from {
    transform: translate3d(45vw, 101vh, 0);
  }
  to {
    transform: translate3d(60vw, -121vh, 0);
  }
}
@keyframes move-frames-159 {
  from {
    transform: translate3d(45vw, 101vh, 0);
  }
  to {
    transform: translate3d(60vw, -121vh, 0);
  }
}
.circle-container:nth-child(159) .circle {
  -webkit-animation-delay: 1043ms;
  animation-delay: 1043ms;
}
.circle-container:nth-child(160) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-160;
  animation-name: move-frames-160;
  -webkit-animation-duration: 29974ms;
  animation-duration: 29974ms;
  -webkit-animation-delay: 29788ms;
  animation-delay: 29788ms;
}
@-webkit-keyframes move-frames-160 {
  from {
    transform: translate3d(100vw, 107vh, 0);
  }
  to {
    transform: translate3d(64vw, -112vh, 0);
  }
}
@keyframes move-frames-160 {
  from {
    transform: translate3d(100vw, 107vh, 0);
  }
  to {
    transform: translate3d(64vw, -112vh, 0);
  }
}
.circle-container:nth-child(160) .circle {
  -webkit-animation-delay: 3365ms;
  animation-delay: 3365ms;
}
.circle-container:nth-child(161) {
  width: 7px;
  height: 7px;
  -webkit-animation-name: move-frames-161;
  animation-name: move-frames-161;
  -webkit-animation-duration: 31062ms;
  animation-duration: 31062ms;
  -webkit-animation-delay: 6314ms;
  animation-delay: 6314ms;
}
@-webkit-keyframes move-frames-161 {
  from {
    transform: translate3d(37vw, 102vh, 0);
  }
  to {
    transform: translate3d(86vw, -116vh, 0);
  }
}
@keyframes move-frames-161 {
  from {
    transform: translate3d(37vw, 102vh, 0);
  }
  to {
    transform: translate3d(86vw, -116vh, 0);
  }
}
.circle-container:nth-child(161) .circle {
  -webkit-animation-delay: 1160ms;
  animation-delay: 1160ms;
}
.circle-container:nth-child(162) {
  width: 1px;
  height: 1px;
  -webkit-animation-name: move-frames-162;
  animation-name: move-frames-162;
  -webkit-animation-duration: 31040ms;
  animation-duration: 31040ms;
  -webkit-animation-delay: 18866ms;
  animation-delay: 18866ms;
}
@-webkit-keyframes move-frames-162 {
  from {
    transform: translate3d(11vw, 108vh, 0);
  }
  to {
    transform: translate3d(78vw, -110vh, 0);
  }
}
@keyframes move-frames-162 {
  from {
    transform: translate3d(11vw, 108vh, 0);
  }
  to {
    transform: translate3d(78vw, -110vh, 0);
  }
}
.circle-container:nth-child(162) .circle {
  -webkit-animation-delay: 14ms;
  animation-delay: 14ms;
}
.circle-container:nth-child(163) {
  width: 2px;
  height: 2px;
  -webkit-animation-name: move-frames-163;
  animation-name: move-frames-163;
  -webkit-animation-duration: 29959ms;
  animation-duration: 29959ms;
  -webkit-animation-delay: 30692ms;
  animation-delay: 30692ms;
}
@-webkit-keyframes move-frames-163 {
  from {
    transform: translate3d(44vw, 104vh, 0);
  }
  to {
    transform: translate3d(89vw, -106vh, 0);
  }
}
@keyframes move-frames-163 {
  from {
    transform: translate3d(44vw, 104vh, 0);
  }
  to {
    transform: translate3d(89vw, -106vh, 0);
  }
}
.circle-container:nth-child(163) .circle {
  -webkit-animation-delay: 2525ms;
  animation-delay: 2525ms;
}
.circle-container:nth-child(164) {
  width: 8px;
  height: 8px;
  -webkit-animation-name: move-frames-164;
  animation-name: move-frames-164;
  -webkit-animation-duration: 36565ms;
  animation-duration: 36565ms;
  -webkit-animation-delay: 418ms;
  animation-delay: 418ms;
}
@-webkit-keyframes move-frames-164 {
  from {
    transform: translate3d(2vw, 106vh, 0);
  }
  to {
    transform: translate3d(76vw, -124vh, 0);
  }
}
@keyframes move-frames-164 {
  from {
    transform: translate3d(2vw, 106vh, 0);
  }
  to {
    transform: translate3d(76vw, -124vh, 0);
  }
}
.circle-container:nth-child(164) .circle {
  -webkit-animation-delay: 3592ms;
  animation-delay: 3592ms;
}
.circle-container:nth-child(165) {
  width: 1px;
  height: 1px;
  -webkit-animation-name: move-frames-165;
  animation-name: move-frames-165;
  -webkit-animation-duration: 29786ms;
  animation-duration: 29786ms;
  -webkit-animation-delay: 2437ms;
  animation-delay: 2437ms;
}
@-webkit-keyframes move-frames-165 {
  from {
    transform: translate3d(67vw, 102vh, 0);
  }
  to {
    transform: translate3d(44vw, -103vh, 0);
  }
}
@keyframes move-frames-165 {
  from {
    transform: translate3d(67vw, 102vh, 0);
  }
  to {
    transform: translate3d(44vw, -103vh, 0);
  }
}
.circle-container:nth-child(165) .circle {
  -webkit-animation-delay: 800ms;
  animation-delay: 800ms;
}
.circle-container:nth-child(166) {
  width: 8px;
  height: 8px;
  -webkit-animation-name: move-frames-166;
  animation-name: move-frames-166;
  -webkit-animation-duration: 32690ms;
  animation-duration: 32690ms;
  -webkit-animation-delay: 7345ms;
  animation-delay: 7345ms;
}
@-webkit-keyframes move-frames-166 {
  from {
    transform: translate3d(54vw, 107vh, 0);
  }
  to {
    transform: translate3d(70vw, -119vh, 0);
  }
}
@keyframes move-frames-166 {
  from {
    transform: translate3d(54vw, 107vh, 0);
  }
  to {
    transform: translate3d(70vw, -119vh, 0);
  }
}
.circle-container:nth-child(166) .circle {
  -webkit-animation-delay: 1230ms;
  animation-delay: 1230ms;
}
.circle-container:nth-child(167) {
  width: 8px;
  height: 8px;
  -webkit-animation-name: move-frames-167;
  animation-name: move-frames-167;
  -webkit-animation-duration: 29790ms;
  animation-duration: 29790ms;
  -webkit-animation-delay: 536ms;
  animation-delay: 536ms;
}
@-webkit-keyframes move-frames-167 {
  from {
    transform: translate3d(2vw, 106vh, 0);
  }
  to {
    transform: translate3d(12vw, -107vh, 0);
  }
}
@keyframes move-frames-167 {
  from {
    transform: translate3d(2vw, 106vh, 0);
  }
  to {
    transform: translate3d(12vw, -107vh, 0);
  }
}
.circle-container:nth-child(167) .circle {
  -webkit-animation-delay: 2473ms;
  animation-delay: 2473ms;
}
.circle-container:nth-child(168) {
  width: 6px;
  height: 6px;
  -webkit-animation-name: move-frames-168;
  animation-name: move-frames-168;
  -webkit-animation-duration: 36369ms;
  animation-duration: 36369ms;
  -webkit-animation-delay: 22317ms;
  animation-delay: 22317ms;
}
@-webkit-keyframes move-frames-168 {
  from {
    transform: translate3d(98vw, 101vh, 0);
  }
  to {
    transform: translate3d(8vw, -106vh, 0);
  }
}
@keyframes move-frames-168 {
  from {
    transform: translate3d(98vw, 101vh, 0);
  }
  to {
    transform: translate3d(8vw, -106vh, 0);
  }
}
.circle-container:nth-child(168) .circle {
  -webkit-animation-delay: 3262ms;
  animation-delay: 3262ms;
}
.circle-container:nth-child(169) {
  width: 6px;
  height: 6px;
  -webkit-animation-name: move-frames-169;
  animation-name: move-frames-169;
  -webkit-animation-duration: 36724ms;
  animation-duration: 36724ms;
  -webkit-animation-delay: 19457ms;
  animation-delay: 19457ms;
}
@-webkit-keyframes move-frames-169 {
  from {
    transform: translate3d(6vw, 110vh, 0);
  }
  to {
    transform: translate3d(27vw, -133vh, 0);
  }
}
@keyframes move-frames-169 {
  from {
    transform: translate3d(6vw, 110vh, 0);
  }
  to {
    transform: translate3d(27vw, -133vh, 0);
  }
}
.circle-container:nth-child(169) .circle {
  -webkit-animation-delay: 830ms;
  animation-delay: 830ms;
}
.circle-container:nth-child(170) {
  width: 2px;
  height: 2px;
  -webkit-animation-name: move-frames-170;
  animation-name: move-frames-170;
  -webkit-animation-duration: 30108ms;
  animation-duration: 30108ms;
  -webkit-animation-delay: 25651ms;
  animation-delay: 25651ms;
}
@-webkit-keyframes move-frames-170 {
  from {
    transform: translate3d(20vw, 103vh, 0);
  }
  to {
    transform: translate3d(8vw, -130vh, 0);
  }
}
@keyframes move-frames-170 {
  from {
    transform: translate3d(20vw, 103vh, 0);
  }
  to {
    transform: translate3d(8vw, -130vh, 0);
  }
}
.circle-container:nth-child(170) .circle {
  -webkit-animation-delay: 1189ms;
  animation-delay: 1189ms;
}
.circle-container:nth-child(171) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-171;
  animation-name: move-frames-171;
  -webkit-animation-duration: 33415ms;
  animation-duration: 33415ms;
  -webkit-animation-delay: 14801ms;
  animation-delay: 14801ms;
}
@-webkit-keyframes move-frames-171 {
  from {
    transform: translate3d(28vw, 101vh, 0);
  }
  to {
    transform: translate3d(36vw, -115vh, 0);
  }
}
@keyframes move-frames-171 {
  from {
    transform: translate3d(28vw, 101vh, 0);
  }
  to {
    transform: translate3d(36vw, -115vh, 0);
  }
}
.circle-container:nth-child(171) .circle {
  -webkit-animation-delay: 1129ms;
  animation-delay: 1129ms;
}
.circle-container:nth-child(172) {
  width: 5px;
  height: 5px;
  -webkit-animation-name: move-frames-172;
  animation-name: move-frames-172;
  -webkit-animation-duration: 29416ms;
  animation-duration: 29416ms;
  -webkit-animation-delay: 7737ms;
  animation-delay: 7737ms;
}
@-webkit-keyframes move-frames-172 {
  from {
    transform: translate3d(18vw, 102vh, 0);
  }
  to {
    transform: translate3d(87vw, -116vh, 0);
  }
}
@keyframes move-frames-172 {
  from {
    transform: translate3d(18vw, 102vh, 0);
  }
  to {
    transform: translate3d(87vw, -116vh, 0);
  }
}
.circle-container:nth-child(172) .circle {
  -webkit-animation-delay: 1025ms;
  animation-delay: 1025ms;
}
.circle-container:nth-child(173) {
  width: 5px;
  height: 5px;
  -webkit-animation-name: move-frames-173;
  animation-name: move-frames-173;
  -webkit-animation-duration: 31912ms;
  animation-duration: 31912ms;
  -webkit-animation-delay: 36342ms;
  animation-delay: 36342ms;
}
@-webkit-keyframes move-frames-173 {
  from {
    transform: translate3d(85vw, 107vh, 0);
  }
  to {
    transform: translate3d(16vw, -137vh, 0);
  }
}
@keyframes move-frames-173 {
  from {
    transform: translate3d(85vw, 107vh, 0);
  }
  to {
    transform: translate3d(16vw, -137vh, 0);
  }
}
.circle-container:nth-child(173) .circle {
  -webkit-animation-delay: 685ms;
  animation-delay: 685ms;
}
.circle-container:nth-child(174) {
  width: 1px;
  height: 1px;
  -webkit-animation-name: move-frames-174;
  animation-name: move-frames-174;
  -webkit-animation-duration: 30890ms;
  animation-duration: 30890ms;
  -webkit-animation-delay: 26630ms;
  animation-delay: 26630ms;
}
@-webkit-keyframes move-frames-174 {
  from {
    transform: translate3d(96vw, 105vh, 0);
  }
  to {
    transform: translate3d(65vw, -111vh, 0);
  }
}
@keyframes move-frames-174 {
  from {
    transform: translate3d(96vw, 105vh, 0);
  }
  to {
    transform: translate3d(65vw, -111vh, 0);
  }
}
.circle-container:nth-child(174) .circle {
  -webkit-animation-delay: 3386ms;
  animation-delay: 3386ms;
}
.circle-container:nth-child(175) {
  width: 8px;
  height: 8px;
  -webkit-animation-name: move-frames-175;
  animation-name: move-frames-175;
  -webkit-animation-duration: 30658ms;
  animation-duration: 30658ms;
  -webkit-animation-delay: 32065ms;
  animation-delay: 32065ms;
}
@-webkit-keyframes move-frames-175 {
  from {
    transform: translate3d(41vw, 105vh, 0);
  }
  to {
    transform: translate3d(6vw, -125vh, 0);
  }
}
@keyframes move-frames-175 {
  from {
    transform: translate3d(41vw, 105vh, 0);
  }
  to {
    transform: translate3d(6vw, -125vh, 0);
  }
}
.circle-container:nth-child(175) .circle {
  -webkit-animation-delay: 3450ms;
  animation-delay: 3450ms;
}
.circle-container:nth-child(176) {
  width: 2px;
  height: 2px;
  -webkit-animation-name: move-frames-176;
  animation-name: move-frames-176;
  -webkit-animation-duration: 31958ms;
  animation-duration: 31958ms;
  -webkit-animation-delay: 26494ms;
  animation-delay: 26494ms;
}
@-webkit-keyframes move-frames-176 {
  from {
    transform: translate3d(80vw, 108vh, 0);
  }
  to {
    transform: translate3d(21vw, -133vh, 0);
  }
}
@keyframes move-frames-176 {
  from {
    transform: translate3d(80vw, 108vh, 0);
  }
  to {
    transform: translate3d(21vw, -133vh, 0);
  }
}
.circle-container:nth-child(176) .circle {
  -webkit-animation-delay: 637ms;
  animation-delay: 637ms;
}
.circle-container:nth-child(177) {
  width: 8px;
  height: 8px;
  -webkit-animation-name: move-frames-177;
  animation-name: move-frames-177;
  -webkit-animation-duration: 35261ms;
  animation-duration: 35261ms;
  -webkit-animation-delay: 4543ms;
  animation-delay: 4543ms;
}
@-webkit-keyframes move-frames-177 {
  from {
    transform: translate3d(70vw, 102vh, 0);
  }
  to {
    transform: translate3d(89vw, -117vh, 0);
  }
}
@keyframes move-frames-177 {
  from {
    transform: translate3d(70vw, 102vh, 0);
  }
  to {
    transform: translate3d(89vw, -117vh, 0);
  }
}
.circle-container:nth-child(177) .circle {
  -webkit-animation-delay: 3866ms;
  animation-delay: 3866ms;
}
.circle-container:nth-child(178) {
  width: 2px;
  height: 2px;
  -webkit-animation-name: move-frames-178;
  animation-name: move-frames-178;
  -webkit-animation-duration: 34180ms;
  animation-duration: 34180ms;
  -webkit-animation-delay: 26908ms;
  animation-delay: 26908ms;
}
@-webkit-keyframes move-frames-178 {
  from {
    transform: translate3d(42vw, 106vh, 0);
  }
  to {
    transform: translate3d(78vw, -127vh, 0);
  }
}
@keyframes move-frames-178 {
  from {
    transform: translate3d(42vw, 106vh, 0);
  }
  to {
    transform: translate3d(78vw, -127vh, 0);
  }
}
.circle-container:nth-child(178) .circle {
  -webkit-animation-delay: 1211ms;
  animation-delay: 1211ms;
}
.circle-container:nth-child(179) {
  width: 8px;
  height: 8px;
  -webkit-animation-name: move-frames-179;
  animation-name: move-frames-179;
  -webkit-animation-duration: 34444ms;
  animation-duration: 34444ms;
  -webkit-animation-delay: 935ms;
  animation-delay: 935ms;
}
@-webkit-keyframes move-frames-179 {
  from {
    transform: translate3d(32vw, 103vh, 0);
  }
  to {
    transform: translate3d(20vw, -120vh, 0);
  }
}
@keyframes move-frames-179 {
  from {
    transform: translate3d(32vw, 103vh, 0);
  }
  to {
    transform: translate3d(20vw, -120vh, 0);
  }
}
.circle-container:nth-child(179) .circle {
  -webkit-animation-delay: 797ms;
  animation-delay: 797ms;
}
.circle-container:nth-child(180) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-180;
  animation-name: move-frames-180;
  -webkit-animation-duration: 36602ms;
  animation-duration: 36602ms;
  -webkit-animation-delay: 16271ms;
  animation-delay: 16271ms;
}
@-webkit-keyframes move-frames-180 {
  from {
    transform: translate3d(89vw, 109vh, 0);
  }
  to {
    transform: translate3d(24vw, -128vh, 0);
  }
}
@keyframes move-frames-180 {
  from {
    transform: translate3d(89vw, 109vh, 0);
  }
  to {
    transform: translate3d(24vw, -128vh, 0);
  }
}
.circle-container:nth-child(180) .circle {
  -webkit-animation-delay: 2039ms;
  animation-delay: 2039ms;
}
.circle-container:nth-child(181) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-181;
  animation-name: move-frames-181;
  -webkit-animation-duration: 28683ms;
  animation-duration: 28683ms;
  -webkit-animation-delay: 7895ms;
  animation-delay: 7895ms;
}
@-webkit-keyframes move-frames-181 {
  from {
    transform: translate3d(5vw, 107vh, 0);
  }
  to {
    transform: translate3d(7vw, -122vh, 0);
  }
}
@keyframes move-frames-181 {
  from {
    transform: translate3d(5vw, 107vh, 0);
  }
  to {
    transform: translate3d(7vw, -122vh, 0);
  }
}
.circle-container:nth-child(181) .circle {
  -webkit-animation-delay: 138ms;
  animation-delay: 138ms;
}
.circle-container:nth-child(182) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-182;
  animation-name: move-frames-182;
  -webkit-animation-duration: 30458ms;
  animation-duration: 30458ms;
  -webkit-animation-delay: 31061ms;
  animation-delay: 31061ms;
}
@-webkit-keyframes move-frames-182 {
  from {
    transform: translate3d(2vw, 107vh, 0);
  }
  to {
    transform: translate3d(45vw, -112vh, 0);
  }
}
@keyframes move-frames-182 {
  from {
    transform: translate3d(2vw, 107vh, 0);
  }
  to {
    transform: translate3d(45vw, -112vh, 0);
  }
}
.circle-container:nth-child(182) .circle {
  -webkit-animation-delay: 1168ms;
  animation-delay: 1168ms;
}
.circle-container:nth-child(183) {
  width: 6px;
  height: 6px;
  -webkit-animation-name: move-frames-183;
  animation-name: move-frames-183;
  -webkit-animation-duration: 33543ms;
  animation-duration: 33543ms;
  -webkit-animation-delay: 31571ms;
  animation-delay: 31571ms;
}
@-webkit-keyframes move-frames-183 {
  from {
    transform: translate3d(73vw, 109vh, 0);
  }
  to {
    transform: translate3d(47vw, -125vh, 0);
  }
}
@keyframes move-frames-183 {
  from {
    transform: translate3d(73vw, 109vh, 0);
  }
  to {
    transform: translate3d(47vw, -125vh, 0);
  }
}
.circle-container:nth-child(183) .circle {
  -webkit-animation-delay: 3694ms;
  animation-delay: 3694ms;
}
.circle-container:nth-child(184) {
  width: 4px;
  height: 4px;
  -webkit-animation-name: move-frames-184;
  animation-name: move-frames-184;
  -webkit-animation-duration: 34092ms;
  animation-duration: 34092ms;
  -webkit-animation-delay: 3428ms;
  animation-delay: 3428ms;
}
@-webkit-keyframes move-frames-184 {
  from {
    transform: translate3d(98vw, 104vh, 0);
  }
  to {
    transform: translate3d(29vw, -111vh, 0);
  }
}
@keyframes move-frames-184 {
  from {
    transform: translate3d(98vw, 104vh, 0);
  }
  to {
    transform: translate3d(29vw, -111vh, 0);
  }
}
.circle-container:nth-child(184) .circle {
  -webkit-animation-delay: 1924ms;
  animation-delay: 1924ms;
}
.circle-container:nth-child(185) {
  width: 1px;
  height: 1px;
  -webkit-animation-name: move-frames-185;
  animation-name: move-frames-185;
  -webkit-animation-duration: 32323ms;
  animation-duration: 32323ms;
  -webkit-animation-delay: 25813ms;
  animation-delay: 25813ms;
}
@-webkit-keyframes move-frames-185 {
  from {
    transform: translate3d(46vw, 104vh, 0);
  }
  to {
    transform: translate3d(99vw, -105vh, 0);
  }
}
@keyframes move-frames-185 {
  from {
    transform: translate3d(46vw, 104vh, 0);
  }
  to {
    transform: translate3d(99vw, -105vh, 0);
  }
}
.circle-container:nth-child(185) .circle {
  -webkit-animation-delay: 1960ms;
  animation-delay: 1960ms;
}
.circle-container:nth-child(186) {
  width: 4px;
  height: 4px;
  -webkit-animation-name: move-frames-186;
  animation-name: move-frames-186;
  -webkit-animation-duration: 36701ms;
  animation-duration: 36701ms;
  -webkit-animation-delay: 34423ms;
  animation-delay: 34423ms;
}
@-webkit-keyframes move-frames-186 {
  from {
    transform: translate3d(86vw, 103vh, 0);
  }
  to {
    transform: translate3d(40vw, -116vh, 0);
  }
}
@keyframes move-frames-186 {
  from {
    transform: translate3d(86vw, 103vh, 0);
  }
  to {
    transform: translate3d(40vw, -116vh, 0);
  }
}
.circle-container:nth-child(186) .circle {
  -webkit-animation-delay: 1654ms;
  animation-delay: 1654ms;
}
.circle-container:nth-child(187) {
  width: 5px;
  height: 5px;
  -webkit-animation-name: move-frames-187;
  animation-name: move-frames-187;
  -webkit-animation-duration: 30417ms;
  animation-duration: 30417ms;
  -webkit-animation-delay: 23259ms;
  animation-delay: 23259ms;
}
@-webkit-keyframes move-frames-187 {
  from {
    transform: translate3d(15vw, 103vh, 0);
  }
  to {
    transform: translate3d(19vw, -110vh, 0);
  }
}
@keyframes move-frames-187 {
  from {
    transform: translate3d(15vw, 103vh, 0);
  }
  to {
    transform: translate3d(19vw, -110vh, 0);
  }
}
.circle-container:nth-child(187) .circle {
  -webkit-animation-delay: 3032ms;
  animation-delay: 3032ms;
}
.circle-container:nth-child(188) {
  width: 5px;
  height: 5px;
  -webkit-animation-name: move-frames-188;
  animation-name: move-frames-188;
  -webkit-animation-duration: 32146ms;
  animation-duration: 32146ms;
  -webkit-animation-delay: 29408ms;
  animation-delay: 29408ms;
}
@-webkit-keyframes move-frames-188 {
  from {
    transform: translate3d(16vw, 101vh, 0);
  }
  to {
    transform: translate3d(87vw, -120vh, 0);
  }
}
@keyframes move-frames-188 {
  from {
    transform: translate3d(16vw, 101vh, 0);
  }
  to {
    transform: translate3d(87vw, -120vh, 0);
  }
}
.circle-container:nth-child(188) .circle {
  -webkit-animation-delay: 2235ms;
  animation-delay: 2235ms;
}
.circle-container:nth-child(189) {
  width: 7px;
  height: 7px;
  -webkit-animation-name: move-frames-189;
  animation-name: move-frames-189;
  -webkit-animation-duration: 32975ms;
  animation-duration: 32975ms;
  -webkit-animation-delay: 30777ms;
  animation-delay: 30777ms;
}
@-webkit-keyframes move-frames-189 {
  from {
    transform: translate3d(10vw, 107vh, 0);
  }
  to {
    transform: translate3d(1vw, -122vh, 0);
  }
}
@keyframes move-frames-189 {
  from {
    transform: translate3d(10vw, 107vh, 0);
  }
  to {
    transform: translate3d(1vw, -122vh, 0);
  }
}
.circle-container:nth-child(189) .circle {
  -webkit-animation-delay: 2669ms;
  animation-delay: 2669ms;
}
.circle-container:nth-child(190) {
  width: 5px;
  height: 5px;
  -webkit-animation-name: move-frames-190;
  animation-name: move-frames-190;
  -webkit-animation-duration: 31592ms;
  animation-duration: 31592ms;
  -webkit-animation-delay: 34932ms;
  animation-delay: 34932ms;
}
@-webkit-keyframes move-frames-190 {
  from {
    transform: translate3d(93vw, 106vh, 0);
  }
  to {
    transform: translate3d(12vw, -123vh, 0);
  }
}
@keyframes move-frames-190 {
  from {
    transform: translate3d(93vw, 106vh, 0);
  }
  to {
    transform: translate3d(12vw, -123vh, 0);
  }
}
.circle-container:nth-child(190) .circle {
  -webkit-animation-delay: 2821ms;
  animation-delay: 2821ms;
}
.circle-container:nth-child(191) {
  width: 4px;
  height: 4px;
  -webkit-animation-name: move-frames-191;
  animation-name: move-frames-191;
  -webkit-animation-duration: 35864ms;
  animation-duration: 35864ms;
  -webkit-animation-delay: 5052ms;
  animation-delay: 5052ms;
}
@-webkit-keyframes move-frames-191 {
  from {
    transform: translate3d(23vw, 101vh, 0);
  }
  to {
    transform: translate3d(55vw, -123vh, 0);
  }
}
@keyframes move-frames-191 {
  from {
    transform: translate3d(23vw, 101vh, 0);
  }
  to {
    transform: translate3d(55vw, -123vh, 0);
  }
}
.circle-container:nth-child(191) .circle {
  -webkit-animation-delay: 2709ms;
  animation-delay: 2709ms;
}
.circle-container:nth-child(192) {
  width: 7px;
  height: 7px;
  -webkit-animation-name: move-frames-192;
  animation-name: move-frames-192;
  -webkit-animation-duration: 32279ms;
  animation-duration: 32279ms;
  -webkit-animation-delay: 13938ms;
  animation-delay: 13938ms;
}
@-webkit-keyframes move-frames-192 {
  from {
    transform: translate3d(62vw, 104vh, 0);
  }
  to {
    transform: translate3d(62vw, -134vh, 0);
  }
}
@keyframes move-frames-192 {
  from {
    transform: translate3d(62vw, 104vh, 0);
  }
  to {
    transform: translate3d(62vw, -134vh, 0);
  }
}
.circle-container:nth-child(192) .circle {
  -webkit-animation-delay: 3820ms;
  animation-delay: 3820ms;
}
.circle-container:nth-child(193) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-193;
  animation-name: move-frames-193;
  -webkit-animation-duration: 36035ms;
  animation-duration: 36035ms;
  -webkit-animation-delay: 5896ms;
  animation-delay: 5896ms;
}
@-webkit-keyframes move-frames-193 {
  from {
    transform: translate3d(81vw, 105vh, 0);
  }
  to {
    transform: translate3d(5vw, -119vh, 0);
  }
}
@keyframes move-frames-193 {
  from {
    transform: translate3d(81vw, 105vh, 0);
  }
  to {
    transform: translate3d(5vw, -119vh, 0);
  }
}
.circle-container:nth-child(193) .circle {
  -webkit-animation-delay: 3538ms;
  animation-delay: 3538ms;
}
.circle-container:nth-child(194) {
  width: 5px;
  height: 5px;
  -webkit-animation-name: move-frames-194;
  animation-name: move-frames-194;
  -webkit-animation-duration: 28509ms;
  animation-duration: 28509ms;
  -webkit-animation-delay: 10768ms;
  animation-delay: 10768ms;
}
@-webkit-keyframes move-frames-194 {
  from {
    transform: translate3d(25vw, 101vh, 0);
  }
  to {
    transform: translate3d(1vw, -127vh, 0);
  }
}
@keyframes move-frames-194 {
  from {
    transform: translate3d(25vw, 101vh, 0);
  }
  to {
    transform: translate3d(1vw, -127vh, 0);
  }
}
.circle-container:nth-child(194) .circle {
  -webkit-animation-delay: 3462ms;
  animation-delay: 3462ms;
}
.circle-container:nth-child(195) {
  width: 4px;
  height: 4px;
  -webkit-animation-name: move-frames-195;
  animation-name: move-frames-195;
  -webkit-animation-duration: 36266ms;
  animation-duration: 36266ms;
  -webkit-animation-delay: 9050ms;
  animation-delay: 9050ms;
}
@-webkit-keyframes move-frames-195 {
  from {
    transform: translate3d(79vw, 107vh, 0);
  }
  to {
    transform: translate3d(58vw, -118vh, 0);
  }
}
@keyframes move-frames-195 {
  from {
    transform: translate3d(79vw, 107vh, 0);
  }
  to {
    transform: translate3d(58vw, -118vh, 0);
  }
}
.circle-container:nth-child(195) .circle {
  -webkit-animation-delay: 1158ms;
  animation-delay: 1158ms;
}
.circle-container:nth-child(196) {
  width: 8px;
  height: 8px;
  -webkit-animation-name: move-frames-196;
  animation-name: move-frames-196;
  -webkit-animation-duration: 34905ms;
  animation-duration: 34905ms;
  -webkit-animation-delay: 33175ms;
  animation-delay: 33175ms;
}
@-webkit-keyframes move-frames-196 {
  from {
    transform: translate3d(59vw, 102vh, 0);
  }
  to {
    transform: translate3d(42vw, -117vh, 0);
  }
}
@keyframes move-frames-196 {
  from {
    transform: translate3d(59vw, 102vh, 0);
  }
  to {
    transform: translate3d(42vw, -117vh, 0);
  }
}
.circle-container:nth-child(196) .circle {
  -webkit-animation-delay: 872ms;
  animation-delay: 872ms;
}
.circle-container:nth-child(197) {
  width: 4px;
  height: 4px;
  -webkit-animation-name: move-frames-197;
  animation-name: move-frames-197;
  -webkit-animation-duration: 32238ms;
  animation-duration: 32238ms;
  -webkit-animation-delay: 8059ms;
  animation-delay: 8059ms;
}
@-webkit-keyframes move-frames-197 {
  from {
    transform: translate3d(71vw, 107vh, 0);
  }
  to {
    transform: translate3d(76vw, -136vh, 0);
  }
}
@keyframes move-frames-197 {
  from {
    transform: translate3d(71vw, 107vh, 0);
  }
  to {
    transform: translate3d(76vw, -136vh, 0);
  }
}
.circle-container:nth-child(197) .circle {
  -webkit-animation-delay: 3167ms;
  animation-delay: 3167ms;
}
.circle-container:nth-child(198) {
  width: 8px;
  height: 8px;
  -webkit-animation-name: move-frames-198;
  animation-name: move-frames-198;
  -webkit-animation-duration: 35956ms;
  animation-duration: 35956ms;
  -webkit-animation-delay: 19041ms;
  animation-delay: 19041ms;
}
@-webkit-keyframes move-frames-198 {
  from {
    transform: translate3d(35vw, 109vh, 0);
  }
  to {
    transform: translate3d(62vw, -134vh, 0);
  }
}
@keyframes move-frames-198 {
  from {
    transform: translate3d(35vw, 109vh, 0);
  }
  to {
    transform: translate3d(62vw, -134vh, 0);
  }
}
.circle-container:nth-child(198) .circle {
  -webkit-animation-delay: 3875ms;
  animation-delay: 3875ms;
}
.circle-container:nth-child(199) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-199;
  animation-name: move-frames-199;
  -webkit-animation-duration: 30966ms;
  animation-duration: 30966ms;
  -webkit-animation-delay: 34925ms;
  animation-delay: 34925ms;
}
@-webkit-keyframes move-frames-199 {
  from {
    transform: translate3d(75vw, 110vh, 0);
  }
  to {
    transform: translate3d(21vw, -140vh, 0);
  }
}
@keyframes move-frames-199 {
  from {
    transform: translate3d(75vw, 110vh, 0);
  }
  to {
    transform: translate3d(21vw, -140vh, 0);
  }
}
.circle-container:nth-child(199) .circle {
  -webkit-animation-delay: 1041ms;
  animation-delay: 1041ms;
}
.circle-container:nth-child(200) {
  width: 4px;
  height: 4px;
  -webkit-animation-name: move-frames-200;
  animation-name: move-frames-200;
  -webkit-animation-duration: 29316ms;
  animation-duration: 29316ms;
  -webkit-animation-delay: 31149ms;
  animation-delay: 31149ms;
}
@-webkit-keyframes move-frames-200 {
  from {
    transform: translate3d(59vw, 107vh, 0);
  }
  to {
    transform: translate3d(50vw, -127vh, 0);
  }
}
@keyframes move-frames-200 {
  from {
    transform: translate3d(59vw, 107vh, 0);
  }
  to {
    transform: translate3d(50vw, -127vh, 0);
  }
}
.circle-container:nth-child(200) .circle {
  -webkit-animation-delay: 2050ms;
  animation-delay: 2050ms;
}

.message {
  position: absolute;
  right: 20px;
  bottom: 10px;
  color: white;
  font-family: "Josefin Slab", serif;
  line-height: 27px;
  font-size: 18px;
  text-align: right;
  pointer-events: none;
  -webkit-animation: message-frames 1.5s ease 5s forwards;
  animation: message-frames 1.5s ease 5s forwards;
  opacity: 0;
}
@-webkit-keyframes message-frames {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes message-frames {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
