.sitemenu_partner_with_us {
  display: flex;
  justify-content: center;
  padding-top: 200px;
  background-image: linear-gradient(to right, #fe024c, #03a8e3);
  padding-bottom: 100px;
}

.sitemenu_partner_with_us_contact_us {
  padding-top: 20px;
  background-image: linear-gradient(to right, #fe024c, #03a8e3);
  padding-bottom: 100px;
}

.sitemenu_partner_with_us_inner {
  width: 90%;
  display: flex;
  justify-content: center;
}

.sitemenu_partner_with_us_inner_div1 {
  width: 50%;
}

.sitemenu_partner_with_us_inner_div1_h1 {
  font-size: 40px;
  font-weight: 600;
  color: white;
}

.sitemenu_partner_with_us_inner_div1_p1 {
  margin-top: 20px;
  color: white;
  font-size: 20px;
  font-weight: 500;
  opacity: 0.9;
}

.sitemenu_partner_with_us_inner_div1_p2 {
  margin-top: 20px;
  color: white;
  font-size: 20px;
  font-weight: 300;
  opacity: 0.9;
}

.sitemenu_partner_with_us_inner_div1_p22 {
  margin-top: 70px;
  color: white;
  font-size: 20px;
  font-weight: 300;
  opacity: 0.9;
}

.sitemenu_partner_with_us_inner_div1_div1 {
  margin-top: 40px;
}

.sitemenu_partner_with_us_inner_div1_div1_button {
  font-size: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: white;
  padding-left: 50px;
  padding-right: 50px;
  color: #fe024c;
  font-weight: 700;
  border-radius: 15px;
}

.sitemenu_partner_with_us_inner_div2 {
  width: 50%;
}

.sitemenu_partner_with_us_inner_div2 {
  margin-top: 10px;
}

.sitemenu_partner_with_us_inner_div2_form1 {
  padding-left: 10%;
  padding-right: 10%;
}

.sitemenu_partner_with_us_inner_div2_form1_margin {
  margin-top: 30px;
}

.sitemenu_partner_with_us_inner_div2_form1_p {
  color: white;
}

.sitemenu_partner_with_us_inner_div2_form1_input {
  width: 100%;
  border: none;
  outline: none;
  background: none;
  border-bottom: 2px solid #a197c2;
  transition: border-bottom-color 0.3s ease;
  padding: 5px;
  color: white;
  margin-top: 5px;
}

.sitemenu_partner_with_us_inner_div2_form1_input:focus {
  border-color: inherit;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.sitemenu_partner_with_us_inner_div2_form1_input::placeholder {
  color: white;
  opacity: 0.8;
  bottom: 0;
}

.sitemenu_partner_with_us_inner_div1_img {
  width: 100%;
  border-radius: 10px;
  margin-top: 30px;
}

.sitemenu_partner_with_us_inner_div1_img2 {
  width: 100%;
  border-radius: 10px;
  margin-top: 70px;
}

.sitemenu_partner_with_us_inner_div2_about {
  width: 50%;
  padding-left: 5%;
}

@media screen and (max-width: 768px) {
  .sitemenu_partner_with_us_contact_us {
    padding-top: 0px;
    background-image: linear-gradient(to right, #fe024c, #03a8e3);
    padding-bottom: 100px;
  }
}
